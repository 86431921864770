import { useCallback } from "react";
import { useHistory } from "react-router-dom";

export const useResetPageToOne = () => {
  const history = useHistory();

  const setPageOne = useCallback(() => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", "1");
    history.replace({ search: searchParams.toString() });
  }, [history]);

  return setPageOne;
};
