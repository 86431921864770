import { WorkspaceAuthStrategies, WorkspaceKeys } from "../types";

import { routes } from "./internal-api-routes";

export const internalApiWorkspace = {
  id: WorkspaceKeys.Internal,
  name: "Internal API",
  config: {
    baseUrl: `${process.env.API_BASE_URL}/internal-api/v1`,
    loginPath: "/public/auth/login",
    refreshPath: "/public/auth/refresh",
    pathToTokens: "data",
    authStrategies: [
      WorkspaceAuthStrategies.Hmac,
      WorkspaceAuthStrategies.Bearer,
    ],
  },
  routes,
};
