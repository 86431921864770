import {
  UseMutateAsyncFunction,
  UseMutationResult,
} from "@tanstack/react-query";

import { AxiosResponse } from "axios";

import { apiClient } from "@/lib/api";
import { getErrorMessage } from "@/lib/axios-helpers";
import {
  createAxiosMutation,
  CreateMutationOptions,
} from "@/hooks/react-query";

import {
  AcceptInviteRequest,
  AccessTokenResponse,
  AuthLoginRequest,
  AuthRefreshRequest,
  ForgotPasswordRequest,
  ResetPasswordRequest,
  SignedIdTokenAuthRequest,
} from "./types";

class AuthQueryClient {
  public useAcceptInviteMutation: (
    options?: CreateMutationOptions<
      AxiosResponse<AcceptInviteRequest>,
      any,
      AcceptInviteRequest,
      unknown
    >
  ) => readonly [
    UseMutateAsyncFunction<AxiosResponse, any, AcceptInviteRequest, unknown>,
    UseMutationResult<AxiosResponse, any, AcceptInviteRequest, unknown>,
  ];

  public useLinkAccountMutation: (
    options?: CreateMutationOptions<
      AxiosResponse<any>,
      any,
      SignedIdTokenAuthRequest,
      unknown
    >
  ) => readonly [
    UseMutateAsyncFunction<
      AxiosResponse<any>,
      any,
      SignedIdTokenAuthRequest,
      unknown
    >,
    UseMutationResult<
      AxiosResponse<any>,
      any,
      SignedIdTokenAuthRequest,
      unknown
    >,
  ];

  public useForgotPasswordRequestMutation: (
    options?: CreateMutationOptions<
      AxiosResponse<ForgotPasswordRequest>,
      any,
      ForgotPasswordRequest,
      unknown
    >
  ) => readonly [
    UseMutateAsyncFunction<AxiosResponse, any, ForgotPasswordRequest, unknown>,
    UseMutationResult<AxiosResponse, any, ForgotPasswordRequest, unknown>,
  ];

  public useLoginMutation: (
    options?: CreateMutationOptions<
      AxiosResponse<AccessTokenResponse>,
      any,
      AuthLoginRequest,
      unknown
    >
  ) => readonly [
    UseMutateAsyncFunction<
      AxiosResponse<AccessTokenResponse>,
      any,
      AuthLoginRequest,
      unknown
    >,
    UseMutationResult<
      AxiosResponse<AccessTokenResponse>,
      any,
      AuthLoginRequest,
      unknown
    >,
  ];

  public useRefreshTokenMutation: (
    options?: CreateMutationOptions<
      AxiosResponse<AccessTokenResponse>,
      any,
      AuthRefreshRequest,
      unknown
    >
  ) => readonly [
    UseMutateAsyncFunction<
      AxiosResponse<AccessTokenResponse>,
      any,
      AuthRefreshRequest,
      unknown
    >,
    UseMutationResult<
      AxiosResponse<AccessTokenResponse>,
      any,
      AuthRefreshRequest,
      unknown
    >,
  ];

  public useResetPasswordMutation: (
    options?: CreateMutationOptions<
      AxiosResponse<ResetPasswordRequest>,
      any,
      ResetPasswordRequest,
      unknown
    >
  ) => readonly [
    UseMutateAsyncFunction<AxiosResponse, any, ResetPasswordRequest, unknown>,
    UseMutationResult<AxiosResponse, any, ResetPasswordRequest, unknown>,
  ];

  constructor() {
    this.useAcceptInviteMutation = createAxiosMutation(
      async (data: AcceptInviteRequest) => {
        return apiClient.post(`/v1/public/auth/invitations/accept`, data);
      },
      {
        errorToast: (err: any) => ({
          status: "error",
          message: `Unable To Accept Invitation: ${getErrorMessage(err)}`,
        }),
      }
    );

    this.useForgotPasswordRequestMutation = createAxiosMutation(
      async (data: ForgotPasswordRequest) => {
        return apiClient.post(`/v1/public/auth/forgot-password`, data);
      },
      {
        errorToast: (err: any) => ({
          status: "error",
          message: `Unable To Send Forgot Password Request: ${getErrorMessage(
            err
          )}`,
        }),
      }
    );

    // Note: as seen in AuthLoginRequest, we expect an email+password OR id_token
    this.useLoginMutation = createAxiosMutation(
      async (payload: AuthLoginRequest) => {
        return apiClient.post<AccessTokenResponse>(
          "/v1/public/auth/login",
          payload,
          {
            withCredentials: true,
          }
        );
      }
    );

    // Mutation for linking an AA user account to an umbrella user in Central Station
    this.useLinkAccountMutation = createAxiosMutation(
      async ({ id_token }: SignedIdTokenAuthRequest) => {
        return apiClient.post<any>(`/v1/public/link-account/${id_token}`, {
          withCredentials: true,
        });
      }
    );

    this.useRefreshTokenMutation = createAxiosMutation(
      async ({ fingerprint_hash, refresh_token }: AuthRefreshRequest) => {
        return apiClient.post<AccessTokenResponse>(
          "/v1/public/auth/refresh",
          {
            fingerprint_hash,
            refresh_token,
          },
          {
            withCredentials: true,
          }
        );
      }
    );

    this.useResetPasswordMutation = createAxiosMutation(
      async (data: ResetPasswordRequest) => {
        return apiClient.post(`/v1/public/auth/reset-password`, data);
      },
      {
        errorToast: (err: any) => ({
          status: "error",
          message: `Unable To Reset Password: ${getErrorMessage(err)}`,
        }),
      }
    );
  }
}

export const AuthQueries = new AuthQueryClient();
