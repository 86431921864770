import { Route } from "badmagic/dist/types";

import { paginationParams } from "../shared";

export const organizationRoutes: Route[] = [
  {
    name: "List all organizations",
    path: "/organizations",
    qsParams: [{ name: "name" }, ...paginationParams([{ value: "name" }])],
  },
  {
    name: "Create Organization",
    path: "/organizations",
    method: "POST",
    body: [{ name: "name" }],
  },
  {
    name: "Get organization by ID",
    path: "/organizations/:organization_id",
  },
  {
    name: "Update an Organization",
    path: "/organizations/:organization_id",
    method: "PATCH",
    body: [{ name: "name" }],
  },
];
