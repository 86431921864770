import { parse, stringify, ParsedUrlQueryInput } from "querystring";
import { truncate, omitBy, isNil } from "lodash-es";
import { History, Location } from "history";

export function toTitleCase(str: string | null | undefined) {
  if (!str) return "";
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function pluralizeLabel(str: string, count: number) {
  return `${str}${count > 1 ? "s" : ""}`;
}

export function humanize(str: string | null | undefined) {
  if (!str) return "";
  if (str.replaceAll) {
    return toTitleCase(str.replaceAll("_", " ").replaceAll("-", " "));
  }
  return str;
}

/**
 * Converts "SomeCamelCase" or "someCamelCase" to "Some Camel Case"
 */
export function humanizeCamelCase(str: string | null | undefined) {
  if (!str) {
    return "";
  } else if (str.replace) {
    return str
      .replace(/((?<!^)[A-Z](?![A-Z]))(?=\S)/g, " $1")
      .replace(/^./, (s) => s.toUpperCase());
  } else {
    return str;
  }
}

export function randomPin(length = 6): string {
  const add = 1;
  let max = 12 - add; // 12 is the min safe number Math.random() can generate without it starting to pad the end with zeros.

  if (length > max) {
    return randomPin(max) + randomPin(length - max);
  }

  max = Math.pow(10, length + add);
  const min = max / 10; // Math.pow(10, n) basically
  const number = Math.floor(Math.random() * (max - min + 1)) + min;

  return ("" + number).substring(add);
}

export function truncateString(str?: string, length: number = 15) {
  if (!str) return "";
  return truncate(str, {
    length,
    omission: "...",
  });
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * https://stackoverflow.com/a/11832950
 *
 * @param num the number to ceil
 * @returns the number ceiled to two places with floating point protection
 */
export function conservativeTwoDecimalCeil(num: number) {
  return Math.ceil((num + Number.EPSILON) * 100) / 100;
}

type UpdateQSProps = {
  history: History;
  location: Location;
  update: ParsedUrlQueryInput;
  qs?: ParsedUrlQueryInput;
  method?: "push" | "replace";

  /**
   * Whether the updated querystring should drop keys whose value is null,
   * undefined, or an empty string.
   */
  omitEmpty?: boolean;
};

/**
 * Sample usage:
 *
 * Helpers.updateQS({
 *  history,
 *  update: { marketing_names },
 *  location,
 *  qs,
 * })
 */
export function updateQS({
  history,
  update,
  location,
  qs,
  method = "push",
  omitEmpty = false,
}: UpdateQSProps) {
  const mergedQs = { ...qs, ...update };
  const updatedQs = omitEmpty
    ? omitBy(mergedQs, (v) => isNil(v) || v === "")
    : mergedQs;

  return history[method](
    `${location.pathname}${
      Object.keys(updatedQs).length ? `?${stringify(updatedQs)}` : ""
    }`
  );
}

export function qsFromLocation(location: any) {
  return location && location.search
    ? parse(location.search.replace("?", ""))
    : {};
}
