import { humanize } from "@/lib/helpers";

export interface MPLConfig {
  num_cardholders?: number;
  channel_id?: number;
  connection_string?: string;
  daylight_savings?: boolean;
  gmt_offset?: number;
  scp_id?: number;
  transactions?: number;
  activation_date?: DateStorage;
  deactivation_date?: DateStorage;
  use_limit?: boolean;
  controller_id?: number;
}

enum DateStorage {
  none = "none",
  dateOnly = "date_only",
  dateAndTime = "date_and_time",
}

export const DataStorageOptions = [
  { label: humanize(DateStorage.none), value: DateStorage.none },
  { label: humanize(DateStorage.dateOnly), value: DateStorage.dateOnly },
  { label: humanize(DateStorage.dateAndTime), value: DateStorage.dateAndTime },
];

export const GmtOffsetOptions = [
  { label: "(UTC-05:00) Eastern Time (US & Canada)", value: 18000 },
  { label: "(UTC-06:00) Central Time (US & Canada)", value: 21600 },
  { label: "(UTC-07:00) Mountain Time (US & Canada)", value: 25200 },
  { label: "(UTC-08:00) Pacific Time (US & Canada)", value: 28800 },
  { label: "(UTC-09:00) Alaska", value: 32400 },
  { label: "(UTC-10:00) Hawaii", value: 36000 },
  { label: "(UTC) Dublin, Edinburgh, Lisbon, London", value: 0 },
  { label: "(UTC+04:00) Moscow, St. Petersburg, Volgograd", value: -14400 },
  { label: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi", value: -28800 },
];
