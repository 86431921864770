export enum QueryKeys {
  AccessLevelSchedules = "access-level-schedules",
  AccessLevels = "access-levels",
  ApiApplications = "api-applications",
  CallRooms = "call-rooms",
  CardFormats = "card-formats",
  CascadingOptions = "cascading-options",
  Controllers = "controllers",
  Credentials = "credentials",
  Doors = "doors",
  Elevators = "elevators",
  ElevatorAccessLevels = "elevator-access-levels",
  EncodeCredential = "encode-credential",
  Encoders = "encoders",
  Events = "events",
  ExternalUsers = "external-users",
  FeatureFlags = "feature-flags",
  Firmware = "firmware",
  Floors = "floors",
  HolidayScheduleGroups = "holiday-schedule-groups",
  HolidaySchedules = "holiday-schedules",
  Hubs = "hubs",
  Inputs = "inputs",
  MemberAccessLevels = "member-access-levels",
  MemberDoors = "member-doors",
  Members = "members",
  Operators = "operators",
  Organizations = "organizations",
  Outputs = "outputs",
  Panels = "panels",
  Readers = "readers",
  ReadersWarbler = "readers-warbler",
  ScheduleHolidayGroups = "schedule-holiday-groups",
  ScheduleInterval = "schedule-intervals",
  Schedules = "schedules",
  SipAccounts = "sip-accounts",
  SiteFirmware = "site-firmware",
  SiteImports = "site-imports",
  Sites = "sites",
  SiteNotificationRecipients = "site-notification-recipients",
  SiteAlerts = "site-alerts",
  SystemHealth = "system-overview",
  Users = "users",
  UserActivityLogs = "user-activity-logs",
}
