import { Route } from "badmagic/dist/types";

import { format } from "date-fns";

import { ValidCredentialTypes } from "@/modules/credential/types";

export const credentialRoutes: Route[] = [
  {
    name: "Get a credential by ID",
    path: `/credentials/:credential_id`,
  },
  {
    name: "Encode Credential",
    path: `/credentials/:credential_id/encode`,
    method: "POST",
  },
  {
    name: "List Credentials",
    path: `/credentials`,
    qsParams: [{ name: "member_id", required: true }],
  },
  {
    name: "Create credential",
    path: `/credentials`,
    method: "POST",
    body: [
      { name: "member_id", required: true },
      {
        name: "type",
        required: true,
        options: ValidCredentialTypes.map((type) => ({
          label: type,
          value: type,
        })),
      },
      { name: "pin", type: "number" },
      { name: "number", type: "number" },
      { name: "card_format_id", type: "number" },
      {
        name: "start_at",
        placeholder: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      },
      {
        name: "end_at",
        placeholder: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      },
    ],
  },
  {
    name: "Delete credential",
    path: `/credentials/:credential_id`,
    method: "DELETE",
  },
  {
    name: "Lost credential",
    path: `/credentials/:credential_id/lost`,
    method: "DELETE",
  },
  {
    name: "Generate Vault",
    method: "POST",
    path: "/credentials/vault/generate",
    body: [{ name: "door_id", required: true }],
    documentation: "Generate a vault of credentials for give door.",
  },
];
