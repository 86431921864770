import { QueryKeys } from "@/types";

import { BaseQueryClient } from "../base/queries";

import { CallRoom } from "./types";

const queryKey = QueryKeys.CallRooms;

class CallRoomQueryClient extends BaseQueryClient<CallRoom> {
  constructor() {
    super({ queryKey });
  }
}
export const CallRoomQueries = new CallRoomQueryClient();
