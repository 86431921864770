import { QueryKeys } from "@/types";

import { BaseQueryClient } from "../base/queries";

import { AccessLevel } from "./types";

const queryKey = QueryKeys.AccessLevels;

class AccessLevelQueryClient extends BaseQueryClient<AccessLevel> {
  constructor() {
    super({ queryKey });
  }
}
export const AccessLevelQueries = new AccessLevelQueryClient();
