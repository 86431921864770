import { QueryKeys } from "@/types";

import { BaseQueryClient } from "../base/queries";

import { SiteNotificationRecipient } from "./types";

const queryKey = QueryKeys.SiteNotificationRecipients;

class SiteNotificationRecipientQueryClient extends BaseQueryClient<SiteNotificationRecipient> {
  constructor() {
    super({ queryKey });
  }
}
export const SiteNotificationRecipientQueries =
  new SiteNotificationRecipientQueryClient();
