import { QueryKeys } from "@/types";

import { BaseQueryClient } from "../base/queries";

import { Organization } from "./types";

const queryKey = QueryKeys.Organizations;

class OrganizationQueryClient extends BaseQueryClient<Organization> {
  constructor() {
    super({ queryKey });
  }
}
export const OrganizationQueries = new OrganizationQueryClient();
