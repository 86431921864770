import { humanize } from "@/lib/helpers";

import { BaseRecord } from "../base/types";
import { Door } from "../door/types";
import { Panel } from "../panel/types";

export interface Input extends BaseRecord {
  name?: string;
  input_number?: number;
  synced_at?: string;
  icvt_num?: IcvtNumber;
  debounce?: number;
  hold_time?: number;
  panel_id?: number;
  status?: string;
  channel?: string;
  panel?: Panel;
  rex1?: Door;
  rex2?: Door;
  dsm1?: Door;
  supervised?: boolean;
  input_type: InputType;
  samples: InputSample[];
  door?: Door;
}

export enum InputType {
  aux = "AUX",
}

export enum IcvtNumber {
  closed = 0,
  open = 1,
  eol1 = 2,
  eol2 = 3,
}

export interface InputSample {
  low: number;
  high: number;
  state: InputSampleState;
}

export enum InputSampleState {
  short = "short",
  normal = "normal",
  alarmed = "alarmed",
  open = "open",
}

export const InputSampleStateOptions = [
  { label: humanize(InputSampleState.short), value: InputSampleState.short },
  { label: humanize(InputSampleState.normal), value: InputSampleState.normal },
  {
    label: humanize(InputSampleState.alarmed),
    value: InputSampleState.alarmed,
  },
  { label: humanize(InputSampleState.open), value: InputSampleState.open },
];

export const ValidInputSampleStates = [
  InputSampleState.short,
  InputSampleState.normal,
  InputSampleState.alarmed,
  InputSampleState.open,
];

const HumanReadableIcvtNumber = {
  [IcvtNumber.closed]: "Normally Closed, no EOL",
  [IcvtNumber.open]: "Normally Open, no EOL",
  [IcvtNumber.eol1]: "Standard (ROM’ed) EOL: 1 kΩ normal, 2 kΩ active",
  [IcvtNumber.eol2]: "Standard (ROM’ed) EOL: 2 kΩ normal, 1 kΩ active",
};

export const IcvtOptions = [
  {
    value: IcvtNumber.closed,
    label: HumanReadableIcvtNumber[IcvtNumber.closed],
  },
  {
    value: IcvtNumber.open,
    label: HumanReadableIcvtNumber[IcvtNumber.open],
  },
  {
    value: IcvtNumber.eol1,
    label: HumanReadableIcvtNumber[IcvtNumber.eol1],
  },
  {
    value: IcvtNumber.eol2,
    label: HumanReadableIcvtNumber[IcvtNumber.eol2],
  },
];

export const ValidIcvtOptions = [
  IcvtNumber.closed,
  IcvtNumber.open,
  IcvtNumber.eol1,
  IcvtNumber.eol2,
];
