import { BaseRecord } from "../base/types";

export interface CardFormat extends BaseRecord {
  name?: string;
  site_id: number;
  format: CardFormatType;
  facility_code?: number;
  offset?: number;
  format_number?: number;
  type?: string;
  first_number: number;
  last_number?: number;
  pending_delete?: string;
  pending_action?: string;
  controllers?: CardFormatController[];
  card_count?: number;
}

interface CardFormatController {
  synced_at: string;
  controller_id: number;
  card_format_id: number;
}

enum CardFormatType {
  standard26Bit = "Standard 26 Bit",
  hid10302 = "37 Bit (HID 10302)",
  hid10304 = "37 Bit (HID 10304)",
  wavelynx32Bit = "32 Bit Wavelynx",
  wavelynx56Bit = "56 Bit Wavelynx Leaf",
  card48x = "48x Bit Schlage",
  nedap_upass_osdp = "Nedap uPASS 96 Bit OSDP (W26)",
  nedap_upass_wiegand = "Nedap uPASS 26 Bit",
  transcore_cypress_cpsg_37_bit = "Transcore/Cypress CPSG 37 bit",
  wally_40 = "Wally 40 Bit",
}

export const ValidCardFormats = [
  CardFormatType.standard26Bit,
  CardFormatType.hid10302,
  CardFormatType.hid10304,
  CardFormatType.wavelynx32Bit,
  CardFormatType.wavelynx56Bit,
  CardFormatType.card48x,
  CardFormatType.nedap_upass_osdp,
  CardFormatType.nedap_upass_wiegand,
  CardFormatType.transcore_cypress_cpsg_37_bit,
  CardFormatType.wally_40,
];

export const CardFormatOptions = [
  { label: CardFormatType.standard26Bit, value: CardFormatType.standard26Bit },
  { label: CardFormatType.hid10302, value: CardFormatType.hid10302 },
  { label: CardFormatType.hid10304, value: CardFormatType.hid10304 },
  { label: CardFormatType.wavelynx32Bit, value: CardFormatType.wavelynx32Bit },
  { label: CardFormatType.wavelynx56Bit, value: CardFormatType.wavelynx56Bit },
  { label: CardFormatType.card48x, value: CardFormatType.card48x },
  {
    label: CardFormatType.nedap_upass_osdp,
    value: CardFormatType.nedap_upass_osdp,
  },
  {
    label: CardFormatType.nedap_upass_wiegand,
    value: CardFormatType.nedap_upass_wiegand,
  },
  {
    label: CardFormatType.transcore_cypress_cpsg_37_bit,
    value: CardFormatType.transcore_cypress_cpsg_37_bit,
  },
  { label: CardFormatType.wally_40, value: CardFormatType.wally_40 },
];

// DB is bigint (64 bits) which can allow -9223372036854775808 to +9223372036854775807
// but this is the largest number to use without Javascript/Formkit/whatever
// automatically forcing it to round when past a certain range and parseFloat
// seems to be used in all cases.
//
// i.e. parseFloat(9999999999999997)
// <- 9999999999999996
//
// Even though this max does not match the allowed DB, it will be good enough
// until we figure out how to get around it
export const MAX_CARD_NUMBER = 999999999999999;
export const MIN_CARD_NUMBER = 0;
