import { update } from "lodash-es";

import { createAxiosMutation } from "@/hooks/react-query";
import { apiClient } from "@/lib/api";
import { getErrorMessage } from "@/lib/axios-helpers";
import { QueryKeys } from "@/types";

import { API_BASE_PATH, BaseQueryClient } from "../base/queries";

import { Door, DoorModeRequest } from "./types";

const queryKey = QueryKeys.Doors;

class DoorQueryClient extends BaseQueryClient<Door> {
  public useSetTemporaryModeMutation;

  constructor() {
    super({ queryKey });

    this.useSetTemporaryModeMutation = createAxiosMutation(
      async (doorModeRequest: DoorModeRequest) => {
        const { data } = await apiClient.post(
          `${API_BASE_PATH}/${this.queryKey}/${doorModeRequest.id}/mode`,
          doorModeRequest
        );
        return data;
      },
      {
        successToast: () => ({
          status: "success",
          message: "Successfully Updated Temporary Door Mode",
        }),
        errorToast: (err: any) => ({
          status: "error",
          message: `Unable To Update Temporary Door Mode. ${getErrorMessage(
            err
          )}`,
        }),
        onSettled: async (queryClient, result, err, { id }) => {
          // TODO: Filter this query better.
          await queryClient.invalidateQueries([this.queryKey, { id: id }]);
          await queryClient.invalidateQueries([this.queryKey]);
        },
      }
    );

    // `doors.held_open_limit_seconds` is set by a form slider, which can't
    // send a null value when set to zero. Zero is an invalid value for this column,
    // so we ensure to send `null` to the backend.
    this.encode = (values) =>
      update(values, "held_open_limit_seconds", (v) => v || null);
  }
}
export const DoorQueries = new DoorQueryClient();
