import axios, {
  AxiosError,
  InternalAxiosRequestConfig,
  AxiosRequestHeaders,
} from "axios";

export const isAxiosError = (err: any): err is AxiosError => {
  return typeof err === "object" && err.isAxiosError;
};

export const apiClient = axios.create({
  baseURL: `${process.env.API_BASE_URL}/internal-api`,
});

apiClient.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const authStateStr = window.localStorage.getItem("authState");
  if (!authStateStr) {
    return config;
  }

  try {
    const authState = JSON.parse(authStateStr);

    if (!authState) {
      return config;
    }

    const accessToken =
      typeof authState === "object" &&
      "accessToken" in authState &&
      authState.accessToken;

    if (accessToken) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
      } as AxiosRequestHeaders;

      return config;
    } else {
      return config;
    }
  } catch (err) {
    console.error(err);
    return config;
  }
});
