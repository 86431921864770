import { QueryKeys } from "@/types";

import { BaseQueryClient } from "../base/queries";

import { SipAccount } from "./types";

const queryKey = QueryKeys.SipAccounts;

class SipAccountQueryClient extends BaseQueryClient<SipAccount> {
  public updateMessageSuccess = () =>
    "Update Successful. You will need to sync the controller before this change takes effect.";

  constructor() {
    super({ queryKey });
  }
}
export const SipAccountQueries = new SipAccountQueryClient();
