import { QueryKeys } from "@/types";

import { BaseQueryClient } from "../base/queries";

import { Hub } from "./types";

const queryKey = QueryKeys.Hubs;

class HubQueryClient extends BaseQueryClient<Hub> {
  constructor() {
    super({ queryKey });
  }
}
export const HubQueries = new HubQueryClient();
