import { Route } from "badmagic/dist/types";

import { UserRole } from "@/modules/user/types";

import { paginationParams } from "../shared";

export const userRoutes: Route[] = [
  {
    name: "List all users for the current users org",
    path: "/users",
    qsParams: [
      { name: "first_name" },
      { name: "last_name" },
      { name: "role" },
      { name: "name" },
      { name: "email" },
      {
        name: "status",
        options: [{ value: "active" }, { value: "invited" }],
      },
      ...paginationParams([
        { value: "first_name" },
        { value: "last_name" },
        { value: "role" },
        { value: "status" },
      ]),
    ],
  },
  {
    name: "Create user",
    path: "/users",
    method: "POST",
    body: [
      { name: "email" },
      { name: "first_name" },
      { name: "last_name" },
      {
        name: "role",
        options: [
          { label: "Super Admin", value: UserRole.SuperAdmin },
          { label: "Admin", value: UserRole.Admin },
          { label: "Manager", value: UserRole.Manager },
          { label: "Channel Partner", value: UserRole.ChannelPartner },
        ],
      },
    ],
  },
  {
    name: "Get user",
    path: "/users/:user_id",
  },
  {
    name: "Update user",
    path: "/users/:user_id",
    method: "PATCH",
    body: [
      { name: "email" },
      { name: "first_name" },
      { name: "last_name" },
      {
        name: "role",
        options: [
          { label: "Super Admin", value: UserRole.SuperAdmin },
          { label: "Admin", value: UserRole.Admin },
          { label: "Manager", value: UserRole.Manager },
          { label: "Channel Partner", value: UserRole.ChannelPartner },
        ],
      },
    ],
  },
  {
    name: "Delete user",
    path: "/users/:user_id",
    method: "DELETE",
  },
  {
    name: "Resend User Invite",
    path: "/users/:user_id/resend-invite",
    method: "POST",
  },
];
