import { useEffect, useRef } from "react";
import { Channel } from "phoenix";

import { useSocketContext } from "@/context/SocketContext";

export const useChannel = (
  topic: string,
  componentName?: string
): Channel | undefined => {
  const { socket, channelMap, isConnected } = useSocketContext();
  const channel = useRef(socket?.channel(topic));

  useEffect(() => {
    // Wait until we get a connected socket before doing any other work.
    // We don't do any cleanup here since there is no connected socket to
    // do any disconnections from.
    if (!socket || !isConnected) return;

    // Account for any bugs by setting the current count to a minimum of 0
    const currentCount = Math.max(channelMap.get(topic) || 0, 0);
    channelMap.set(topic, currentCount + 1);

    // Only actually join the channel if we're the first caller to attempt to do so
    if (currentCount == 0) {
      // 3. Channel not joined yet, start the channel and join
      channel.current = socket.channel(topic);

      channel.current.onError((e: any) => {
        console.warn(`Error with socket channel ${topic}`, e);
      });

      channel.current
        .join()
        .receive("ok", (_resp) => {})
        .receive("error", (response) => {
          console.error(
            `Error while joining socket channel: ${topic}`,
            response
          );
        })
        .receive("timeout", () => {
          console.error(`Timeout while joining socket channel: ${topic}`);
        });
    }

    return () => {
      // Channel was never joined or already removed
      if (channelMap.get(topic) == undefined || !channel.current) {
        return;
      }

      channelMap.set(topic, (channelMap.get(topic) || 1) - 1);

      if ((channelMap.get(topic) || 0) == 0) {
        channel.current
          .leave()
          .receive("ok", (_resp) => {})
          .receive("error", (response) => {
            console.error(
              `Error while leaving socket channel: ${topic}`,
              response
            );
          })
          .receive("timeout", () => {
            console.error(`Timeout while leaving socket channel: ${topic}`);
          });
      }
    };
  }, [componentName, channelMap, isConnected, socket, topic]);

  return channel.current;
};
