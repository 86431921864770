import { QueryKeys } from "@/types";

import { BaseQueryClient } from "../base/queries";

import { FeatureFlag } from "./types";

const queryKey = QueryKeys.FeatureFlags;

class FeatureFlagQueryClient extends BaseQueryClient<FeatureFlag> {
  constructor() {
    super({ queryKey });
  }
}
export const FeatureFlagQueries = new FeatureFlagQueryClient();
