import { createAxiosMutation } from "@/hooks/react-query";

import { apiClient } from "@/lib/api";

import { QueryKeys } from "@/types";

import { API_BASE_PATH, BaseQueryClient } from "../base/queries";

import { ScheduleHolidayGroupsFormValues } from "./types";

const queryKey = QueryKeys.ScheduleHolidayGroups;

const holidaySchedulesGroupsKey = QueryKeys.HolidayScheduleGroups;

export class ScheduleHolidayGroupQueryClient extends BaseQueryClient<ScheduleHolidayGroupsFormValues> {
  constructor(schedule_id: number | undefined | null) {
    super({ queryKey });
    this.queryKey = holidaySchedulesGroupsKey;

    this.useCreateMutation = createAxiosMutation(
      async ({
        values,
      }: {
        values: Partial<ScheduleHolidayGroupsFormValues>;
      }) => {
        const { data } = await apiClient.post<
          Partial<ScheduleHolidayGroupsFormValues>
        >(
          `${API_BASE_PATH}/schedules/${schedule_id}/${holidaySchedulesGroupsKey}/`,
          values
        );
        return data;
      },
      {
        successToast: () => ({
          status: "success",
          message: this.createMessageSuccess(),
        }),
        errorToast: (err: any) => ({
          status: "error",
          message: this.createMessageFailure(err),
        }),
        onSettled: async (queryClient, result, err, member_id) => {
          await queryClient.invalidateQueries([this.queryKey]);
          if (this.afterModify) {
            await this.afterModify(queryClient);
          }
        },
      }
    );

    this.useUpdateMutation = createAxiosMutation(
      async ({
        id,
        values,
      }: {
        id: number;
        values: ScheduleHolidayGroupsFormValues;
      }) => {
        const { data } = await apiClient.post<ScheduleHolidayGroupsFormValues>(
          `${API_BASE_PATH}/schedules/${schedule_id}/${holidaySchedulesGroupsKey}/`,
          values
        );
        return data;
      },
      {
        successToast: () => ({
          status: "success",
          message: this.createMessageSuccess(),
        }),
        errorToast: (err: any) => ({
          status: "error",
          message: this.createMessageFailure(err),
        }),
        onSettled: async (queryClient, result, err, member_id) => {
          await queryClient.invalidateQueries([this.queryKey]);
          if (this.afterModify) {
            await this.afterModify(queryClient);
          }
        },
      }
    );
  }
}

export const ScheduleHolidayGroupQueries = new ScheduleHolidayGroupQueryClient(
  null
);
