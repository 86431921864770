import { QueryKeys } from "@/types";

import { BaseQueryClient } from "../base/queries";

import { CardFormat } from "./types";

const queryKey = QueryKeys.CardFormats;

class CardFormatQueryClient extends BaseQueryClient<CardFormat> {
  constructor() {
    super({ queryKey });
  }
}
export const CardFormatQueries = new CardFormatQueryClient();
