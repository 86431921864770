import { Workspace } from "badmagic/dist/types";

import { WorkspaceKeys } from "../types";

export const unauthenticatedWorkspace: Workspace = {
  id: WorkspaceKeys.Unauthenticated,
  name: "Unauthenticated API",
  config: {
    baseUrl: `${process.env.API_BASE_URL}/internal-api/v1`,
    loginPath: "/public/auth/login",
    refreshPath: "/public/auth/refresh",
    pathToTokens: "data",
    authStrategies: [],
  },
  routes: [],
};
