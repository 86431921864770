import { Route } from "badmagic/dist/types";

import {
  DoorConfigurationOptions,
  DoorModeOptions,
  TempModeDurationOptions,
} from "@/modules/door/types";

import { paginationParams } from "../shared";

export const doorRoutes: Route[] = [
  {
    name: "List Doors",
    path: `/doors`,
    qsParams: [
      { label: "Site ID", name: "site_id" },
      { label: "Controller ID", name: "controller_id" },
      { label: "Member ID", name: "member_id" },
      { label: "Door Name", name: "name" },
      { label: "Door Type", name: "door_type" },
      ...paginationParams([
        { value: "name", label: "Door Name" },
        { value: "door_type", label: "Door Type" },
      ]),
    ],
  },
  {
    name: "Get Door",
    path: `/doors/:door_id`,
  },
  {
    name: "Create Door",
    path: `/doors`,
    method: "POST",
    body: [
      { label: "Controller ID", name: "controller_id", required: true },
      { label: "Door Name", name: "name", required: true },
      {
        label: "Configuration",
        name: "configuration",
        required: true,
        options: DoorConfigurationOptions,
      },
    ],
  },
  {
    name: "Update Door",
    path: `/doors`,
    method: "PATCH",
    body: [
      { name: "name" },
      { name: "mode", options: DoorModeOptions },
      { name: "configuration", options: DoorModeOptions },
      { name: "reader_id" },
      { name: "strike_id" },
      { name: "rex1_id" },
      { name: "rex2_id" },
      { name: "strike_mode" },
      { name: "strike_min" },
      { name: "strike_max" },
      { name: "unlocked_schedule_id" },
      { name: "default_mode", options: DoorModeOptions },
    ],
  },
  {
    name: "Set a doors Temporary Mode",
    path: `/doors/:door_id/mode`,
    method: "POST",
    body: [
      {
        name: "mode",
        options: [
          { label: "Disabled", value: "disabled" },
          { label: "Unlocked", value: "unlocked" },
          { label: "Facility Code Only", value: "facility_code_only" },
          { label: "Card Only", value: "card_only" },
          { label: "Pin Only", value: "pin_only" },
          { label: "Card Or Pin", value: "card_or_pin" },
        ],
      },
      { name: "duration", options: TempModeDurationOptions },
    ],
  },
  {
    name: "Assign a door to a member",
    method: "POST",
    path: "/member-doors/:member_id/:door_id",
  },
  {
    name: "Set Member Doors",
    path: `/member-doors`,
    method: "PUT",
    body: [
      { name: "member_id", type: "number" },
      {
        name: "door_ids",
        array: true,
        type: "number",
      },
    ],
  },
  {
    name: "Unassign a Door from a members",
    path: `/member-doors/:member_id/:door_id`,
    method: "DELETE",
  },
];
