import { PaginatedResponse } from "@smartrent/hooks";
import { AxiosError } from "axios";
import { QueryClient, QueryKey } from "@tanstack/react-query";

import { createAxiosMutation, createAxiosQuery } from "@/hooks/react-query";
import { QueryKeys } from "@/types";
import { apiClient } from "@/lib/api";

import { API_BASE_PATH, BaseQueryClient } from "../base/queries";

import { Panel } from "./types";

const queryKey = QueryKeys.Panels;

class PanelQueryClient extends BaseQueryClient<Panel> {
  constructor() {
    super({
      afterModify: async (queryClient: QueryClient) => {
        await queryClient.invalidateQueries([QueryKeys.CascadingOptions]);
      },
      queryKey,
    });
  }
}
export const PanelQueries = new PanelQueryClient();

interface CascadeOptionsRequestPayload {
  order_field: "order_in" | "order_out";
  controller_id: Panel["controller_id"];
  panels: Array<Panel>;
}

// POST
export const createCascadeOptionsMutation = createAxiosMutation<
  PaginatedResponse<Panel>,
  AxiosError<any, any>,
  CascadeOptionsRequestPayload,
  {
    axiosQueryKey: QueryKey;
    previousRecords: PaginatedResponse<Panel>;
  }
>(
  async (values) =>
    (
      await apiClient.post<PaginatedResponse<Panel>>(
        `${API_BASE_PATH}/${QueryKeys.Panels}/cascading-options`,
        values
      )
    ).data,
  {
    onMutate: async (queryClient, variables) => {
      const axiosQueryKey = [
        QueryKeys.CascadingOptions,
        {
          controller_id: variables.controller_id,
          order_field: variables.order_field,
        },
      ];
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(axiosQueryKey);

      // Snapshot the previous value
      const previousRecords = queryClient.getQueryData(
        axiosQueryKey
      ) as PaginatedResponse<Panel>;

      // Optimistically update to the new value
      queryClient.setQueryData(axiosQueryKey, (old: any) => ({
        ...old,
        records: variables.panels,
      }));

      // Return a context object with the querykey & snapshotted value
      return { axiosQueryKey, previousRecords };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (queryClient, err, newController, context) => {
      if (context?.axiosQueryKey && context?.previousRecords) {
        queryClient.setQueryData(
          context.axiosQueryKey,
          context.previousRecords
        );
      }
    },
  }
);

interface CascadingOptionsQueryProps {
  controller_id: Panel["controller_id"];
  order_field: keyof Pick<Panel, "order_in" | "order_out">;
}
export const useCascadingOptionsQuery = createAxiosQuery(
  QueryKeys.CascadingOptions,
  async ({ controller_id, order_field }: CascadingOptionsQueryProps) =>
    (
      await apiClient.get<PaginatedResponse<Panel>>(
        `${API_BASE_PATH}/${QueryKeys.Panels}/cascading-options`,
        { params: { controller_id, order_field } }
      )
    ).data
);
