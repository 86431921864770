import { Route } from "badmagic/dist/types";

import { paginationParams } from "../shared";

export const apiApplicationRoutes: Route[] = [
  {
    name: "List API Applications",
    path: `/api-applications`,
    qsParams: [
      { name: "site_id", required: true },
      { name: "name" },
      { name: "key" },
      { name: "webhook_url" },
      ...paginationParams([
        { value: "name", label: "Name" },
        { value: "key", label: "Key" },
      ]),
    ],
  },
  {
    name: "Create API Application",
    path: `/api-applications`,
    method: "POST",
    body: [
      { name: "site_id", required: true },
      { name: "name", required: true },
      { name: "webhook_url" },
    ],
  },
  {
    name: "Get API Application",
    path: `/api-applications/:api_application_id`,
  },
  {
    name: "Update API Application",
    path: `/api-applications/:api_application_id`,
    method: "PATCH",
    body: [{ name: "name" }, { name: "webhook_url" }],
  },
  {
    name: "Delete API Application",
    path: `/api-applications/:api_application_id`,
    method: "DELETE",
  },
];
