export const StrSpecOptions = [
  { value: 1, label: "transactions" },
  { value: 2, label: "time_zones" },
  { value: 3, label: "holidays" },
  { value: 4, label: "msp1_ports" },
  { value: 5, label: "sios" },
  { value: 6, label: "monitor_points" },
  { value: 7, label: "control_points" },
  { value: 8, label: "access_control_readers" },
  { value: 9, label: "access_levels" },
  { value: 10, label: "triggers" },
  { value: 11, label: "procedures" },
  { value: 12, label: "monitor_point_groups" },
  { value: 13, label: "access_areas" },
  { value: 14, label: "elevator_access_levels" },
  { value: 15, label: "cardholder_database" },
  { value: 16, label: "asset_database" },
  { value: 17, label: "bio_db_rsi" },
  { value: 18, label: "bio_db_identix" },
  { value: 19, label: "bio_db_bioscrypt" },
  { value: 20, label: "flash_specs" },
  { value: 21, label: "build_sequence_number" },
  { value: 22, label: "flash_save_status" },
  { value: 23, label: "host_config_free_memory" },
  { value: 24, label: "card_database_free_memory" },
  { value: 25, label: "ips_group_structure_info" },
  { value: 26, label: "access_request_buffers" },
  { value: 27, label: "partition_memory_free_info" },
  { value: 28, label: "read_feature_code" },
  { value: 29, label: "bio_database_osdp_1" },
  { value: 30, label: "bio_database_osdp_2" },
  { value: 31, label: "bio_database_osdp_3" },
  { value: 32, label: "bio_database_osdp_4" },
  { value: 33, label: "web_logins_standard" },
  { value: 34, label: "web_logins_psia" },
  { value: 35, label: "fs_version_info" },
  { value: 36, label: "baseboard_cpu_sn" },
  { value: 37, label: "cpu_sn" },
];
