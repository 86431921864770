import { useState } from "react";
export const DelayLoad = _ref => {
  let {
    msDelay,
    children
  } = _ref;
  const [timeElapsed, setTimeElapsed] = useState(false);
  setTimeout(() => {
    setTimeElapsed(true);
  }, msDelay);
  if (timeElapsed) return children;
  return null;
};