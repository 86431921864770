import { Route } from "badmagic/dist/types";

import { CredentialTypeOptions } from "@/modules/credential/types";
import { EventCodeOptions, EventTypeOptions } from "@/modules/event/types";

import { paginationParams } from "../shared";

export const eventRoutes: Route[] = [
  {
    name: "List Events",
    path: `/events`,
    qsParams: [
      { name: "site_id" },
      { name: "type", options: EventTypeOptions },
      { name: "code", label: "Result Code", options: EventCodeOptions },
      { name: "first_name" },
      { name: "last_name" },
      { name: "member_id", type: "number" },
      { name: "user_id", type: "number" },
      { name: "controller_id", type: "number" },
      { name: "hub_id", type: "number" },
      { name: "door_id", type: "number" },
      { name: "credential_type", options: CredentialTypeOptions },
      ...paginationParams([
        { label: "Type", value: "type" },
        { label: "Code", value: "code" },
        { label: "First Name", value: "first_name" },
        { label: "Last Name", value: "last_name" },
        { label: "Happened At", value: "happened_at" },
      ]),
    ],
  },
  {
    name: "List Event Details",
    path: `/events/:event_id`,
  },
];
