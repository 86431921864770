import { AxiosError } from "axios";

import { QueryKeys } from "@/types";
import { apiClient } from "@/lib/api";
import { getErrorMessage } from "@/lib/axios-helpers";
import {
  AxiosQueryHook,
  createAxiosMutation,
  createAxiosQuery,
} from "@/hooks/react-query";

import { API_BASE_PATH, BaseQueryClient } from "../base/queries";

import { SiteFirmware, UnsetSiteFirmware } from "./types";

const queryKey = QueryKeys.SiteFirmware;

class SiteFirmwareQueryClient extends BaseQueryClient<SiteFirmware> {
  public useListActiveSiteFirmware: AxiosQueryHook<
    any,
    (SiteFirmware | UnsetSiteFirmware)[],
    (SiteFirmware | UnsetSiteFirmware)[],
    AxiosError<any>
  >;

  constructor() {
    super({ queryKey });

    this.useListActiveSiteFirmware = createAxiosQuery(
      queryKey,
      async (siteId: number) => {
        const { data } = await apiClient.get(
          `${API_BASE_PATH}/sites/${siteId}/firmware/active`,
          { params: { site_id: siteId } }
        );

        return data;
      }
    );

    // BaseQueryClient doesn't support nested resources so we have to do it ourselves
    this.useCreateMutation = createAxiosMutation(
      async ({ values }: { values: Partial<SiteFirmware> }) => {
        const { data } = await apiClient.post<Partial<SiteFirmware>>(
          `${API_BASE_PATH}/sites/${values.site_id}/firmware`,
          this.encode(values)
        );
        return data;
      },
      {
        successToast: () => ({
          status: "success",
          message: "Updated Successfully",
        }),
        errorToast: (err: any) => ({
          status: "error",
          message: `Failed to Update. ${getErrorMessage(err)}`,
        }),
        onSettled: async (queryClient, _result, _err, _member_id) => {
          await queryClient.invalidateQueries([this.queryKey]);
        },
      }
    );
  }
}
export const SiteFirmwareQueries = new SiteFirmwareQueryClient();
