import { QueryKeys } from "@/types";

import { BaseQueryClient } from "../base/queries";
import { UserActivityLog } from "../user-activity-logs/types";

export type ExternalUser = {
  id: number;
  email: string;
  remote_id: string;
  user_activity_logs?: UserActivityLog[];
  deleted_at: string;
  inserted_at: string;
  updated_at: string;
};

const queryKey = QueryKeys.ExternalUsers;

class ExternalUserClient extends BaseQueryClient<ExternalUser> {
  constructor() {
    super({ queryKey });
  }
}
export const ExternalUserQueries = new ExternalUserClient();
