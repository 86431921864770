import { QueryKeys } from "@/types";

import { BaseQueryClient } from "../base/queries";

import { Operator } from "./types";

const queryKey = QueryKeys.Operators;

class OperatorQueryClient extends BaseQueryClient<Operator> {
  constructor() {
    super({ queryKey });
  }
}
export const OperatorQueries = new OperatorQueryClient();
