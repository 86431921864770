import { AccessLevel } from "@/modules/access-level/types";
import { Floor } from "@/modules/floor/types";

export const findElevatorAccessLevel = (
  floor: Floor,
  access_level_id: AccessLevel["id"]
) =>
  floor.elevator_access_levels?.find(
    (elevator_access_level) =>
      elevator_access_level.access_level_id === access_level_id
  );
