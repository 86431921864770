import { QueryKeys } from "@/types";

import { BaseQueryClient } from "../base/queries";

import { HolidayScheduleGroup } from "./types";

const queryKey = QueryKeys.HolidayScheduleGroups;

class HolidayScheduleGroupClient extends BaseQueryClient<HolidayScheduleGroup> {
  constructor() {
    super({
      queryKey,
      afterModify: async (queryClient) => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.SiteAlerts],
        });
      },
    });
  }
}

export const HolidayScheduleGroupQueries = new HolidayScheduleGroupClient();
