import { QueryKeys } from "@/types";

import { BaseRecord } from "../base/types";

export interface User extends BaseRecord {
  role?: UserRole;
  organization_id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  invitation_accepted_at?: string;
  inserted_at?: string;
  sites?: number[] | string[];
}

export enum UserRole {
  Admin = "admin",
  SuperAdmin = "superadmin",
  Manager = "manager",
  ChannelPartner = "channelpartner",
  ReadOnly = "readonly",
}

export const getRoleName = (role?: UserRole) => {
  switch (role) {
    case UserRole.SuperAdmin:
      return "Super Admin";
    case UserRole.Admin:
      return "Admin";
    case UserRole.ChannelPartner:
      return "Channel Partner";
    case UserRole.Manager:
      return "Manager";
    case UserRole.ReadOnly:
      return "Readonly";
    default:
      return "";
  }
};

export const userRolesOptions = (currentUserRole?: UserRole) => {
  switch (currentUserRole) {
    case undefined:
    case UserRole.SuperAdmin:
    case UserRole.Admin:
      return [
        { label: "Admin", value: UserRole.Admin },
        { label: "Manager", value: UserRole.Manager },
        { label: "Channel Partner", value: UserRole.ChannelPartner },
      ];
    default:
      return [{ label: "Manager", value: UserRole.Manager }];
  }
};

interface Permission {
  create: boolean;
  show: boolean;
  list: boolean;
  update: boolean;
  delete: boolean;
}

interface SyncPermission {
  sync?: boolean;
  force_sync?: boolean;
}

interface LockdownPermission {
  toggle_lockdown: boolean;
}
export interface PermissionsList {
  [QueryKeys.Doors]: Permission;
  [QueryKeys.Elevators]: Permission;
  [QueryKeys.ElevatorAccessLevels]: Permission;
  [QueryKeys.Members]: Permission;
  [QueryKeys.CascadingOptions]: Permission;
  [QueryKeys.Credentials]: Permission;
  [QueryKeys.AccessLevels]: Permission;
  [QueryKeys.AccessLevelSchedules]: Permission;
  [QueryKeys.MemberAccessLevels]: Permission;
  [QueryKeys.MemberDoors]: Permission;
  [QueryKeys.Users]: Permission;
  [QueryKeys.ExternalUsers]: Permission;
  [QueryKeys.Hubs]: Permission;
  [QueryKeys.Controllers]: Permission & SyncPermission;
  [QueryKeys.FeatureFlags]: Permission;
  [QueryKeys.Firmware]: Permission;
  [QueryKeys.CardFormats]: Permission;
  [QueryKeys.Sites]: Permission & LockdownPermission;
  [QueryKeys.Organizations]: Permission;
  [QueryKeys.ApiApplications]: Permission;
  [QueryKeys.Schedules]: Permission;
  [QueryKeys.ScheduleInterval]: Permission;
  [QueryKeys.SiteImports]: Permission;
  [QueryKeys.Operators]: Permission;
  [QueryKeys.Events]: Permission;
  [QueryKeys.UserActivityLogs]: Permission;
  [QueryKeys.Panels]: Permission & SyncPermission;
  [QueryKeys.Floors]: Permission;
  [QueryKeys.Readers]: Permission;
  [QueryKeys.ReadersWarbler]: Permission;
  [QueryKeys.Inputs]: Permission;
  [QueryKeys.Outputs]: Permission;
  [QueryKeys.CallRooms]: Permission;
  [QueryKeys.SipAccounts]: Permission;
  [QueryKeys.SystemHealth]: Permission;
  [QueryKeys.Encoders]: Permission;
  [QueryKeys.EncodeCredential]: Permission;
  [QueryKeys.HolidayScheduleGroups]: Permission;
  [QueryKeys.HolidaySchedules]: Permission;
  [QueryKeys.ScheduleHolidayGroups]: Permission;
  [QueryKeys.SiteFirmware]: Permission;
  [QueryKeys.SiteNotificationRecipients]: Permission;
  [QueryKeys.SiteAlerts]: Permission;
}
