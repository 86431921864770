import { AxiosRequestConfig } from "axios";
import { QueryClient } from "@tanstack/react-query";

import { PaginatedResponse } from "@smartrent/hooks";
import { ListQueryResponse } from "@smartrent/ui";

import { createAxiosMutation } from "@/hooks/react-query";
import { apiClient } from "@/lib/api";
import { getErrorMessage } from "@/lib/axios-helpers";
import { QueryKeys } from "@/types";

import { TableFilters } from "@/modules/base/table/types";

import { API_BASE_PATH, BaseQueryClient } from "../base/queries";

import { BaseRecord } from "../base/types";

import {
  Controller,
  ControllerConfiguration,
  ControllerFirmwareHistory,
  ControllerLog,
} from "./types";

const queryKey = QueryKeys.Controllers;

class ControllerQueryClient extends BaseQueryClient<Controller> {
  constructor() {
    super({
      // Site firmware is only shown if the site has a corresponding controller model for the firmware.
      // As such, we want to bust the Site Firmware cache if we create/delete a controller.
      afterModify: async (queryClient: QueryClient) => {
        await queryClient.invalidateQueries([QueryKeys.SiteFirmware]);
      },
      queryKey,
    });
  }

  useUpdateControllerConfigurationMutation = createAxiosMutation(
    async ({
      controller_id,
      controllerConfiguration,
    }: {
      controller_id: number;
      controllerConfiguration: ControllerConfiguration;
    }) => {
      const { data } = await apiClient.put<ControllerConfiguration>(
        `/v1/controllers/${controller_id}/configuration`,
        controllerConfiguration
      );
      return data;
    },
    {
      successToast: () => ({
        status: "success",
        message:
          "Successfully sent configuration update The update may take a few seconds",
      }),
      errorToast: (err: any) => ({
        status: "error",
        message: `Unable to update controller configuration. ${getErrorMessage(
          err
        )}`,
      }),
    }
  );

  useUpdateControllerFirmwareMutation = createAxiosMutation(
    async ({
      controllerId,
      firmwareId,
    }: {
      controllerId: number;
      firmwareId: number;
    }) => {
      const { data } = await apiClient.put<ControllerConfiguration>(
        `/v1/controllers/${controllerId}/firmware`,
        { firmware_id: firmwareId }
      );
      return data;
    },
    {
      successToast: () => ({
        status: "success",
        message:
          "Successfully sent firmware update. Check the watch tab to monitor progress.",
      }),
      errorToast: (err: any) => ({
        status: "error",
        message: `Unable to start firmware update. ${getErrorMessage(err)}`,
      }),
    }
  );

  fetchLogs = async ({
    queryKey,
  }: {
    queryKey:
      | ReadonlyArray<unknown>
      | readonly [string, Record<string, unknown>, TableFilters];
  }) => {
    const [, , filters] = queryKey;

    const { data } = await apiClient.get<PaginatedResponse<ControllerLog>>(
      `${API_BASE_PATH}/${this.queryKey}/${
        (filters as { controller_id: number })?.controller_id
      }/logs`,
      {
        params: filters,
      }
    );
    return data as ListQueryResponse<ControllerLog & BaseRecord>;
  };

  async fetchWhooConfig(
    siteId: number,
    controllerId: number,
    options?: AxiosRequestConfig
  ) {
    const { data } = await apiClient.get<{
      version: string;
      build: number;
      is_online: boolean;
      online_details: string;
    } | null>(
      `${API_BASE_PATH}/${this.queryKey}/${controllerId}/fetch-whoo-config?site_id=${siteId}`,
      options
    );

    return data;
  }

  fetchFirmwareHistory = async ({
    queryKey,
  }: {
    queryKey:
      | ReadonlyArray<unknown>
      | readonly [string, Record<string, unknown>, TableFilters];
  }) => {
    const [, , filters] = queryKey;

    const { data } = await apiClient.get<
      PaginatedResponse<ControllerFirmwareHistory>
    >(
      `${API_BASE_PATH}/${this.queryKey}/${
        (filters as { controllerId: number })?.controllerId
      }/firmware-history`,
      {
        params: filters,
      }
    );
    return data as ListQueryResponse<ControllerFirmwareHistory>;
  };

  useStartSyncMutation = createAxiosMutation(
    async ({
      controller_id,
      force,
    }: {
      controller_id: number;
      force: boolean;
    }) => {
      const { data } = await apiClient.post(
        `/v1/controllers/${controller_id}/sync`,
        { force }
      );
      return data;
    },
    {
      successToast: () => ({
        status: "success",
        message: "Sync Started. This may take some time.",
      }),
      errorToast: (err: any) => ({
        status: "error",
        message: `Unable to start sync. ${getErrorMessage(err)}`,
      }),
    }
  );
}
export const ControllerQueries = new ControllerQueryClient();
