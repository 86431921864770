import {
  CloseCircleSolid,
  Door,
  DoorOpen,
  IconProps,
  Lock,
  QuestionCircleBorder,
  ShieldSecure,
  ShieldAlert,
  Unlocked,
  WireGlobe,
  ShieldDisabled,
} from "@smartrent/icons";
import { TextThemeColor } from "@smartrent/ui";

import { humanize } from "@/lib/helpers";

import { secondsToFormattedMinutes } from "@/lib/utils";

import { Event, EventCodes, EventTypes } from "./types";

interface EventDetails {
  icon: React.ComponentType<React.PropsWithChildren<IconProps>>;
  title: string;
  subtitle: string;
  result?: TextThemeColor;
  titleColor?: TextThemeColor;
}

const unknownEvent: EventDetails = {
  icon: QuestionCircleBorder,
  title: "Unknown Event",
  subtitle: "",
  result: "warning",
};

const getSchlageEventDetails: (event: Event) => EventDetails = (
  event: Event
) => {
  const title = event.details?.description || "Unknown Event";
  const subtitle = event.code || "";
  return {
    icon: QuestionCircleBorder,
    title: title,
    subtitle: subtitle,
  };
};

export const getEventDetails: (event: Event) => EventDetails = (
  event: Event
) => {
  switch (event.type) {
    case EventTypes.credential:
      return getCredentialEventDetails(event);
    case EventTypes.door:
      return getDoorEventDetails(event);
    case EventTypes.mode_change:
      return getDoorModeEventDetails(event);
    case EventTypes.door_forced_open:
      return getDoorForcedOpenEventDetails(event);
    case EventTypes.door_held_open:
      return getDoorHeldOpenEventDetails(event);
    case EventTypes.door_secured:
      return getDoorSecuredEventDetails(event);
    case EventTypes.rex:
      return getRexEventDetails(event);
    case EventTypes.schlage:
      return getSchlageEventDetails(event);
    case EventTypes.change_of_state_elevator:
      return getElevatorEventDetails(event);
    case EventTypes.change_of_state_elevator_access:
      return getElevatorAccessEventDetails(event);
    case EventTypes.lockdown:
      return getLockdownEventDetails(event);
    default:
      return getGenericEventDetails(event);
  }
};

const getLockdownEventDetails: (event: Event) => EventDetails = (
  event: Event
) => {
  switch (event.code) {
    case EventCodes.lockdown_activated:
      return {
        title: `Lockdown Activated`,
        subtitle: `Initiated by ${event.first_name} ${event.last_name}`,
        icon: ShieldAlert,
        titleColor: "error",
      };
    case EventCodes.lockdown_deactivated:
      return {
        title: `Lockdown Deactivated`,
        subtitle: `Ended by ${event.first_name} ${event.last_name}`,
        icon: ShieldDisabled,
      };
    default:
      return unknownEvent;
  }
};

const getElevatorAccessEventDetails: (event: Event) => EventDetails = (
  event: Event
) => {
  const numOfFloorsWithAccessed = Object.values(
    event.details?.floors || {}
  ).filter((v) => v).length;
  switch (event.code) {
    case EventCodes.elevator_access:
      return {
        title: `${event.door_name} Floor Access Granted`,
        subtitle: `${getMemberNameFromCredential(
          event
        )} accessed up to ${numOfFloorsWithAccessed} floor${
          numOfFloorsWithAccessed > 1 ? "s" : ""
        } using a ${event.credential_type} credential${getCredentialInfo(
          event
        )}`,
        icon: Unlocked,
      };
    default:
      return unknownEvent;
  }
};

const getElevatorEventDetails: (event: Event) => EventDetails = (
  event: Event
) => {
  switch (event.code) {
    case EventCodes.secure:
      return {
        title: `${event.door_name} Access Status Changed`,
        subtitle: `Floor ${event.details?.floorNumber} access changed from ${event.details?.prevFloorStatus} to ${event.code}`,
        icon: ShieldSecure,
      };
    case EventCodes.public:
      return {
        title: `${event.door_name} Access Status Changed`,
        subtitle: `Floor ${event.details?.floorNumber} access changed from ${event.details?.prevFloorStatus} to ${event.code}`,
        icon: WireGlobe,
        result: "warning",
      };
    default:
      return unknownEvent;
  }
};

const getCredentialEventDetails: (event: Event) => EventDetails = (
  event: Event
) => {
  switch (event.code) {
    case EventCodes.granted_full_test_used:
    case EventCodes.unlock:
    case EventCodes.granted:
      return {
        title: `${event.door_name} Access Granted`,
        subtitle: `${getMemberNameFromCredential(event)} unlocked door using a ${
          event.credential_type
        } credential${getCredentialInfo(event)}`,
        icon: Unlocked,
      };
    case EventCodes.granted_full_test_not_used:
      return {
        title: `${event.door_name} Access Unused`,
        subtitle: `${getMemberNameFromCredential(event)} unlocked door using a ${
          event.credential_type
        } credential${getCredentialInfo(event)}, but door wasn't opened`,
        icon: Unlocked,
      };
    case EventCodes.lock:
      return {
        title: `${event.door_name} Locked`,
        subtitle: `${getMemberNameFromCredential(event)} locked door using a ${
          event.credential_type
        } credential`,
        icon: Lock,
      };
    case EventCodes.accepted_unlocked:
      return {
        title: `${event.door_name} Already Unlocked`,
        subtitle: `${getMemberNameFromCredential(event)} ${
          event.credential_type == "card" ? "scanned" : "entered"
        } ${event.credential_type}${getCredentialInfo(event)}`,
        icon: Unlocked,
      };
    case EventCodes.rejected_incomplete_card_pin_sequence:
      return {
        title: `${event.door_name} Access Denied`,
        subtitle: `${getMemberNameFromCredential(event)} tried to ${
          event.credential_type == "card"
            ? "scan a card, but did not enter a pin"
            : "enter a pin, but did not scan a card"
        }. Both a pin and a card are required to access this door.`,
        icon: CloseCircleSolid,
        result: "error",
      };
    case EventCodes.denied_never_allowed_at_this_reader:
      return {
        title: `${event.door_name} Access Denied`,
        subtitle: `${getMemberNameFromCredential(
          event
        )} doesn't have access to this door${getCredentialInfo(event)}`,
        icon: CloseCircleSolid,
        result: "error",
      };
    case EventCodes.rejected_card_not_on_file:
    case EventCodes.rejected_not_on_file:
      return {
        title: `${event.door_name} Access Denied`,
        subtitle: `Card Not Assigned${getCredentialInfo(event)}`,
        icon: CloseCircleSolid,
        result: "error",
      };
    case EventCodes.rejected_invalid_facility_code:
    case EventCodes.unsupported_card_format:
      return {
        title: `${event.door_name} Access Denied`,
        subtitle: `Card not in card bank${getCredentialInfo(event)}`,
        icon: CloseCircleSolid,
        result: "error",
      };
    case EventCodes.rejected_after_expiration_date:
    case EventCodes.rejected_after_time:
      return {
        title: `${event.door_name} Access Denied`,
        subtitle: `${getMemberNameFromCredential(
          event
        )} attempted to use their credential after the scheduled time period${getCredentialInfo(
          event
        )}`,
        icon: CloseCircleSolid,
        result: "error",
      };
    case EventCodes.rejected_before_activation_date:
    case EventCodes.rejected_before_time:
      return {
        title: `${event.door_name} Access Denied`,
        subtitle: `${getMemberNameFromCredential(
          event
        )} attempted to use their credential before the scheduled time period${getCredentialInfo(
          event
        )}`,
        icon: CloseCircleSolid,
        result: "error",
      };
    case EventCodes.rejected_invalid_time:
      return {
        title: `${event.door_name} Access Denied`,
        subtitle: `${getMemberNameFromCredential(
          event
        )} attempted to use their credential outside of the allowed schedule.`,
        icon: CloseCircleSolid,
        result: "error",
      };
    case EventCodes.denied_invalid_card_format:
      return {
        title: `${event.door_name} Access Denied`,
        subtitle: "Unknown card format",
        icon: CloseCircleSolid,
        result: "error",
      };
    case EventCodes.rejected_locked:
      return {
        title: `${event.door_name} Access Denied`,
        subtitle: "Door is locked and can't be opened with a credential",
        icon: CloseCircleSolid,
        result: "error",
      };
    case EventCodes.rejected_no_door:
      return {
        title: `${event.door_name || ""} Invalid Door`,
        subtitle: `Door doesn't exist and can't be opened`,
        icon: CloseCircleSolid,
        result: "error",
      };
    case EventCodes.rejected_door_disabled:
      return {
        title: `${event.door_name} Door Disabled`,
        subtitle: `Door is currently disabled and can't be opened`,
        icon: CloseCircleSolid,
        result: "error",
      };
    case EventCodes.rejected_door_locked:
      return {
        title: `${event.door_name} Door Locked`,
        subtitle: `Door is currently locked and can't be opened with this credential`,
        icon: CloseCircleSolid,
        result: "error",
      };
    case EventCodes.rejected_invalid_pin:
      return {
        title: `${event.door_name} Access Denied`,
        subtitle: "Invalid PIN entered",
        icon: CloseCircleSolid,
        result: "error",
      };
    case EventCodes.rejected_lockdown:
      return {
        title: `${event.door_name} Access Denied`,
        subtitle: "Door is in lockdown",
        icon: CloseCircleSolid,
        result: "error",
      };

    default:
      return unknownEvent;
  }
};

const getDoorEventDetails: (event: Event) => EventDetails = (event: Event) => {
  if (event.details?.temp_mode) {
    return formatTempModeEvent(event);
  } else {
    return formatDoorEvent(event);
  }
};

const formatDoorEvent: (event: Event) => EventDetails = (event: Event) => {
  switch (event.code) {
    case EventCodes.unlocked:
      return {
        title: `${event.door_name} Mode Changed`,
        subtitle: "This door doesn't require credentials to be accessed",
        icon: DoorOpen,
      };
    case EventCodes.locked:
      return {
        title: `${event.door_name} Mode Changed`,
        subtitle: "This door is locked and can't be opened with a credential",
        icon: DoorOpen,
      };
    case EventCodes.disabled:
      return {
        title: `${event.door_name} Mode Changed`,
        subtitle:
          "This door is in a disabled state and can't be used (reader and rex are disabled)",
        icon: DoorOpen,
      };
    case EventCodes.card_only:
      return {
        title: `${event.door_name} Mode Changed`,
        subtitle: "Using pin codes on this door is not allowed",
        icon: DoorOpen,
      };
    case EventCodes.pin_only:
      return {
        title: `${event.door_name} Mode Changed`,
        subtitle: "Using cards on this door is not allowed",
        icon: DoorOpen,
      };
    case EventCodes.card_or_pin:
      return {
        title: `${event.door_name} Mode Changed`,
        subtitle: "Both cards and pins work on this door",
        icon: DoorOpen,
      };
    default:
      return unknownEvent;
  }
};

const getDoorModeEventDetails: (event: Event) => EventDetails = (
  event: Event
) => {
  if (event.details?.temp_mode) {
    return formatTempModeEvent(event);
  } else {
    return formatModeChangeEvent(event);
  }
};

const formatModeChangeEvent: (event: Event) => EventDetails = (
  event: Event
) => {
  if (event.first_name) {
    return {
      title: `${event.door_name} Mode Changed`,
      subtitle: `${event.first_name} ${
        event.last_name
      } updated the door mode to ${humanize(event.code)}${
        event.details?.prior_mode
          ? ` from ${humanize(event.details.prior_mode)}`
          : ""
      } `,
      icon: Door,
    };
  } else {
    return {
      title: `${event.door_name} Mode Changed`,
      subtitle: `Door mode updated to ${humanize(event.code)}${
        event.details?.prior_mode
          ? ` from ${humanize(event.details.prior_mode)}`
          : ""
      } `,
      icon: Door,
    };
  }
};

const formatTempModeEvent: (event: Event) => EventDetails = (event: Event) => {
  if (event.details.temp_mode == "started") {
    const displayName =
      event.last_name == null
        ? event.first_name
        : `${event.first_name} ${event.last_name}`;
    return {
      title: `${event.door_name} Temporary Mode Started`,
      subtitle: `${displayName} updated the door mode to ${humanize(event.code)} from ${humanize(event.details.prior_mode)}`,
      icon: Door,
    };
  } else {
    return {
      title: `${event.door_name} Temporary Mode Ended`,
      subtitle: `Door mode updated to ${humanize(event.code)} from ${humanize(event.details.prior_mode)}`,
      icon: Door,
    };
  }
};

const getDoorHeldOpenEventDetails: (event: Event) => EventDetails = (
  event: Event
) => {
  return {
    title: `${event.door_name} Ajar`,
    subtitle: `Door held open for more than ${secondsToFormattedMinutes(
      event.details.door_held_open_seconds
    )}`,
    icon: ShieldAlert,
    result: "error",
  };
};

const getDoorForcedOpenEventDetails: (event: Event) => EventDetails = (
  event: Event
) => {
  return {
    title: `${event.door_name} Forced Open`,
    subtitle: `No credential was presented but the door was opened`,
    icon: ShieldAlert,
    result: "error",
  };
};

const getDoorSecuredEventDetails: (event: Event) => EventDetails = (
  event: Event
) => {
  return {
    title: `${event.door_name} Secured`,
    subtitle: `Alarms have subsided`,
    icon: ShieldSecure,
  };
};

const getRexEventDetails: (event: Event) => EventDetails = (event: Event) => {
  switch (event.code) {
    case EventCodes.exit_cycle_door_use_not_verified:
      return {
        title: `${event.door_name || "Unknown Door"} Request To Exit`,
        subtitle: "Door was unlocked by request-to-exit",
        icon: Unlocked,
      };
    default:
      return unknownEvent;
  }
};

const getGenericEventDetails: (event: Event) => EventDetails = (
  event: Event
) => {
  switch (event.code) {
    case EventCodes.unlocking:
      return {
        title: `${event.door_name} Unlocked`,
        subtitle: `${event.first_name} ${event.last_name} unlocked the door using their phone`,
        icon: Unlocked,
      };
    default:
      return unknownEvent;
  }
};

const getMemberNameFromCredential: (event: Event) => string = (
  event: Event
) => {
  if (event.credential?.member) {
    return `${event.credential?.member?.first_name} ${event.credential?.member?.last_name}`;
  } else if (event.first_name && event.last_name) {
    return `${event.first_name} ${event.last_name}`;
  }

  return "Unknown Member";
};

function getCredentialInfo(event: Event) {
  if (event.credential_type == "pin") return "";

  const info = [`Card Number: ${event.details?.cardholder_id || "unknown"}`];

  // We don't always have facility code
  if (event.details?.facility_code)
    info.unshift(`Facility Code: ${event.details.facility_code}`);

  // We don't always have card format
  if (event.details?.format) info.push(`Card Format: ${event.details.format}`);

  return `. ${info.join(", ")}`;
}
