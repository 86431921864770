import { Route } from "badmagic/dist/types";

import { paginationParams } from "../shared";

export const programmableVideoRoutes: Route[] = [
  {
    name: "Get Controller Call Log",
    path: "/programmable-video/:controller_id/logs",
    qsParams: [...paginationParams()],
  },
  {
    name: "Get Call Status",
    path: "/programmable-video/:controller_id/call-status",
  },
];
