import { QueryKeys } from "@/types";
import { BaseQueryClient } from "../base/queries";
import { Encoder } from "./types";
const queryKey = QueryKeys.Encoders;
class EncoderQueryClient extends BaseQueryClient {
  constructor() {
    super({
      queryKey
    });
  }
}
export const EncoderQueries = new EncoderQueryClient();