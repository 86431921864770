import { QueryKeys } from "@/types";

import { BaseQueryClient } from "../base/queries";

import { Output } from "./types";

const queryKey = QueryKeys.Outputs;

class OutputQueryClient extends BaseQueryClient<Output> {
  constructor() {
    super({ queryKey });
  }
}
export const OutputQueries = new OutputQueryClient();
