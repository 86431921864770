import { QueryKeys } from "@/types";

import { BaseQueryClient } from "../base/queries";

import { UserActivityLog } from "./types";

const queryKey = QueryKeys.UserActivityLogs;

class UserActivityLogsClient extends BaseQueryClient<UserActivityLog> {
  constructor() {
    super({ queryKey });
  }
}
export const UserActivityLogQueries = new UserActivityLogsClient();
