// This is intentionally named Credential to keep us from confusing
import { humanize, toTitleCase } from "@/lib/helpers";

import { BaseRecord } from "../base/types";
import { CardFormat } from "../card-format/types";
import { Door } from "../door/types";
import { Member } from "../member/types";
import { SystemTypes } from "../site/types";

// it with the various built in Credential types in our libraries.
export interface Credential extends BaseRecord {
  site_id: number;
  member_id?: number;
  card_format_id?: number;
  remote_id?: string;
  status?: string;
  type: CredentialType | string;
  pin?: string;
  number?: number;
  start_at?: string;
  end_at?: string;
  pending_delete?: string;
  start_at_input?: any;
  end_at_input?: any;
  controllers?: CredentialController[];
  card_format?: CardFormat;
  member?: Member;
  door?: Door;

  // FE only field used to know what params to send to the server
  duration_type?: CredentialDurationOptions;
}

export interface CredentialController {
  synced_at: string;
  credential_id: number;
  controller_id: number;
}

export enum CredentialType {
  card = "card",
  pin = "pin",
  mobile = "mobile",
}

export const ValidCredentialTypes = [
  CredentialType.pin,
  CredentialType.card,
  CredentialType.mobile,
];

export const CredentialTypeOptions = [
  { value: CredentialType.pin, label: humanize(CredentialType.pin) },
  { value: CredentialType.card, label: humanize(CredentialType.card) },
  { value: CredentialType.mobile, label: humanize(CredentialType.mobile) },
];

export enum CredentialDurationOptions {
  permanent = "Permanent",
  temporary = "Temporary",
}

const ValidCredentialDurationOptions = [
  CredentialDurationOptions.permanent,
  CredentialDurationOptions.temporary,
];

export const CredentialDurationSelectOptions =
  ValidCredentialDurationOptions.map((duration) => ({
    label: toTitleCase(duration),
    value: duration,
  }));

export const SitesWithEncodableFobs = [SystemTypes.Salto, SystemTypes.Schlage];

export enum MemberAccessType {
  Credentials = "credentials",
  AccessLevels = "access-level",
  Doors = "doors",
}
