import { SetToastOptions, useToast } from "@smartrent/ui";
import { AxiosError } from "axios";
import { merge } from "lodash-es";
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryClient,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";

export interface AxiosQueryHook<
  TFilters = unknown,
  TQueryFnData = unknown,
  TData = TQueryFnData,
  TError extends Error = AxiosError,
> {
  collectionName: string;
  fetchFn: (filters: TFilters) => Promise<TQueryFnData>;
  (
    filters: TFilters,
    options?: UseQueryOptions<TQueryFnData, TError, TData>
  ): UseQueryResult<TData, TError>;
}

/**
 * createAxiosQuery is a hook factory that returns a customized useQuery hook.
 *
 * @link https://react-query.tanstack.com/reference/useQuery
 *
 * @param collectionOrEntityName - The name of the collection or entity being queried.
 * @param fetchFn - A function that performs the actual query (typically an API call).
 * @param defaultOptions - The default options to use for this query. Note that these
 *                         can be overridden by the second argument passed to the hook
 *                         that this function returns.
 *
 * @template TFilters - Type of variables passed to the query function.
 * @template TQueryFnData - Type of data returned from the query function.
 * @template TData - Type of data returned by the hook. If the `select` option is
 *                   passed, it will be the return type of that function. Otherwise,
 *                   it will always be the same as TQueryFnData.
 * @template TError - Type of error thrown from the query function.
 */
export function createAxiosQuery<
  TFilters = unknown,
  TQueryFnData = unknown,
  TData = TQueryFnData,
  TError extends Error = AxiosError,
>(
  collectionOrEntityName: string,
  fetchFn: (filters: TFilters) => Promise<TQueryFnData>,
  defaultOptions?: UseQueryOptions<TQueryFnData, TError, TData>
): AxiosQueryHook<TFilters, TQueryFnData, TData, TError> {
  const hook = (
    filters: TFilters,
    options?: UseQueryOptions<TQueryFnData, TError, TData>
  ) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks -- false positive
    return useQuery<TQueryFnData, TError, TData>(
      [collectionOrEntityName, filters],
      async () => fetchFn(filters),
      merge(defaultOptions, options)
    );
  };

  hook.collectionName = collectionOrEntityName;
  hook.fetchFn = fetchFn;

  return hook;
}

type Typed = {
  type?: string;
};
type SetToastOptionsWithOptionalType = SetToastOptions & Typed;

/**
 * Mutation options.
 *
 * @link https://react-query.tanstack.com/reference/useMutation
 *
 * @template TData - Type of the data returned from the mutate function.
 * @template TError - Type of error thrown by the mutate function.
 * @template TVariables - Type of variables passed to the mutate function.
 * @template TContext - Type of context value returned from the `onMutate` callback.
 */
export interface CreateMutationOptions<
  TData = unknown,
  TError = AxiosError,
  TVariables = unknown,
  TContext = unknown,
> extends Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    "onMutate" | "onSuccess" | "onError" | "onSettled"
  > {
  /**
   * If provided, a success toast with the returned content will be displayed
   * after the mutation succeeds.
   *
   * @param data - The result returned from the mutate function.
   * @param variables - The variables passed to the mutate function.
   * @param context - The context value returned by the optional `onMutate` callback.
   */
  successToast?: (
    data: TData,
    variables: TVariables,
    context: TContext | undefined
  ) => SetToastOptionsWithOptionalType;

  /**
   * If provided, an error toast with the returned content will be displayed
   * after the mutation fails.
   *
   * @param error - The error thrown by the mutate function.
   * @param variables - The variables passed to the mutate function.
   * @param context - The context value returned by the optional `onMutate` callback.
   */
  errorToast?: (
    error: TError,
    variables: TVariables,
    context: TContext | undefined
  ) => SetToastOptionsWithOptionalType;

  /**
   * Called prior to executing the mutate function. The value returned by this
   * callback is passed as the last argument to the `onSuccess`, `onError`, and
   * `onSettled` callbacks.
   *
   * @param queryClient - The query client being used for this mutation.
   * @param variables - The variables passed to the mutate function.
   *
   * @returns The context value passed to the `onSuccess`, `onError`,
   * and `onSettled` callbacks.
   */
  onMutate?: (
    queryClient: QueryClient,
    variables: TVariables
  ) => Promise<TContext> | TContext;

  /**
   * Called after the mutate function succeeds.
   *
   * @param queryClient - The query client being used for this mutation.
   * @param data - The result returned from the mutate function.
   * @param variables - The variables passed to the mutate function.
   * @param context - The context value returned by the optional `onMutate` callback.
   */
  onSuccess?: (
    queryClient: QueryClient,
    data: TData,
    variables: TVariables,
    context: TContext | undefined
  ) => Promise<void> | void;

  /**
   * Called after the mutate function fails.
   *
   * @param queryClient - The query client being used for this mutation.
   * @param error - The error thrown by the mutate function.
   * @param variables - The variables passed to the mutate function.
   * @param context - The context value returned by the optional `onMutate` callback.
   */
  onError?: (
    queryClient: QueryClient,
    error: TError,
    variables: TVariables,
    context: TContext | undefined
  ) => Promise<void> | void;

  /**
   * Called after the mutate function finishes whether or not it was successful.
   *
   * @param queryClient - The query client being used for this mutation.
   * @param data - The result returned from the mutate function.
   * @param error - The error thrown by the mutate function.
   * @param variables - The variables passed to the mutate function.
   * @param context - The context value returned by the optional `onMutate` callback.
   */
  onSettled?: (
    queryClient: QueryClient,
    data: TData | undefined,
    error: TError | null,
    variables: TVariables,
    context: TContext | undefined
  ) => Promise<void> | void;
}

/**
 * createAxiosMutation is a hook factory that returns a customized useMutation hook.
 *
 * @param mutateFn An async function that performs the actual work of the mutation
 *                 (typically, this will be an API call).
 *
 * @param defaultOptions - The default options to use for this mutation. Note that these
 *                         can be overridden by the first argument passed to the hook
 *                         that this function returns.
 *
 * @template TData - Type of the data returned from the mutate function.
 * @template TError - Type of error thrown by the mutate function.
 * @template TVariables - Type of variables passed to the mutate function.
 * @template TContext - Type of context value returned from the `onMutate` callback.
 */
export function createAxiosMutation<
  TData = unknown,
  TError = AxiosError,
  TVariables = unknown,
  TContext = unknown,
>(
  mutateFn: (variables: TVariables) => Promise<TData>,
  defaultOptions?: CreateMutationOptions<TData, TError, TVariables, TContext>
) {
  return (
    options?: CreateMutationOptions<TData, TError, TVariables, TContext>
  ) => {
    const setToast = useToast();
    const queryClient = useQueryClient();

    const { successToast, errorToast, ...resolvedOptions } = {
      ...defaultOptions,
      ...options,
    };

    const mutation = useMutation<TData, TError, TVariables, TContext>(
      async (variables) => await mutateFn(variables),
      {
        onMutate: (...args) =>
          resolvedOptions.onMutate?.(queryClient, ...args) as
            | TContext
            | Promise<TContext>,
        onSettled: (...args) =>
          resolvedOptions.onSettled?.(queryClient, ...args),
        onSuccess: (...args) => {
          if (successToast) {
            setToast({
              ...successToast(...args),
              type: "success",
            } as SetToastOptionsWithOptionalType);
          }
          return resolvedOptions.onSuccess?.(queryClient, ...args);
        },
        onError: (...args) => {
          if (errorToast) {
            setToast({
              ...errorToast(...args),
              type: "error",
            } as SetToastOptionsWithOptionalType);
          }
          return resolvedOptions.onError?.(queryClient, ...args);
        },
      }
    );

    return [mutation.mutateAsync, mutation] as const;
  };
}
