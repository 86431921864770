import { WorkspaceConfig } from "badmagic";

export enum WorkspaceKeys {
  Api = "Api",
  Internal = "Internal",
  Unauthenticated = "Unauthenticated",
  Pass = "Pass",
}

export enum WorkspaceAuthStrategies {
  Hmac = "hmac",
  Bearer = "bearer",
  PassBearer = "pass-bearer",
}

export interface ProfileProps {
  workspaceConfig: WorkspaceConfig;

  setProfiles: (profiles: Record<string, any>[]) => void;
  profiles: Record<string, any>[];
  setError: (error: string) => void;
  setSuccess: (success: string) => void;

  setActiveProfile: (profile: Record<string, any>) => void;
}

export type RefreshTokenProps = ProfileProps & {
  activeProfile: null | Record<string, any>;
};

interface AuthProfile {
  name?: string;
}
export interface HmacProfile extends AuthProfile {
  key?: string;
  secret?: string;
}

export interface BearerProfile extends AuthProfile {
  accessToken?: string;
  refreshToken?: string;
}

export type AuthProfiles = HmacProfile & BearerProfile;

declare global {
  interface Window {
    websocketAccessToken: any;
  }
}
