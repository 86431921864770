import { useState, useEffect, useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { parseISO, addMinutes } from "date-fns";

import { qsFromLocation, updateQS } from "@/lib/helpers";

import { Site, SiteTimeZones, TimeZoneOffsets } from "@/modules/site/types";

export const useDateRange = (site_timezone: Site["timezone"]) => {
  const location = useLocation();
  const history = useHistory();
  const [dateRange, setDateRange] = useState<{
    start_datetime?: string;
    end_datetime?: string;
  }>({ start_datetime: undefined, end_datetime: undefined });

  const shiftTimezone = useCallback(
    (date: string) => {
      if (!date) return undefined;
      const localOffset = new Date().getTimezoneOffset();
      const siteOffset = TimeZoneOffsets[site_timezone as SiteTimeZones];
      return addMinutes(parseISO(date), localOffset + siteOffset).toISOString();
    },
    [site_timezone]
  );

  useEffect(() => {
    const queryString = qsFromLocation(location);

    setDateRange({
      start_datetime: queryString.start_datetime
        ? shiftTimezone(queryString.start_datetime as string)
        : undefined,
      end_datetime: queryString.end_datetime
        ? shiftTimezone(queryString.end_datetime as string)
        : undefined,
    });
  }, [location, shiftTimezone]);

  const updateQueryString = useCallback(
    (minDate: string | null, maxDate: string | null) => {
      const queryString = qsFromLocation(location);
      updateQS({
        history,
        update: {
          ...queryString,
          start_datetime: minDate,
          end_datetime: maxDate,
        },
        location,
        omitEmpty: true,
      });
    },
    [location, history]
  );

  return {
    dateRange,
    setDateRange,
    updateQueryString,
  };
};
