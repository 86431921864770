import { humanize } from "@/lib/helpers";

import { AccessLevel } from "../access-level/types";
import { BaseRecord } from "../base/types";
import { Door } from "../door/types";

export enum CommunicationPreference {
  None = "none",
  Voice = "voice",
  Video = "video",
  Both = "both",
}

export const ValidCommunicationPreferences = [
  CommunicationPreference.None,
  CommunicationPreference.Voice,
  CommunicationPreference.Video,
  CommunicationPreference.Both,
];

export const CommunicationPreferenceOptions = [
  {
    value: CommunicationPreference.None,
    label: humanize(CommunicationPreference.None),
  },
  {
    value: CommunicationPreference.Voice,
    label: humanize(CommunicationPreference.Voice),
  },
  {
    value: CommunicationPreference.Video,
    label: humanize(CommunicationPreference.Video),
  },
  {
    value: CommunicationPreference.Both,
    label: humanize(CommunicationPreference.Both),
  },
];

export interface Member extends BaseRecord {
  site_id: number;
  first_name?: string;
  last_name?: string;
  nickname?: string;
  phone_number?: string;
  email_address?: string;
  remote_id?: string;
  location?: string;
  department?: string;
  title?: string;
  communication_preference?: CommunicationPreference;
  access_levels?: AccessLevel[];
  doors?: Door[];
  inserted_at?: string;
  updated_at?: string;
}
