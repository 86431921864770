import { Route } from "badmagic/dist/types";

import { CardFormatOptions } from "@/modules/card-format/types";

import { paginationParams } from "../shared";

export const cardRoutes: Route[] = [
  {
    name: "Get Card Formats",
    path: `/card-formats`,
    qsParams: [
      { name: "site_id", required: true },
      { name: "name_like", label: "Name" },
      { name: "facility_code" },
      { name: "include_deleted" },
      { name: "search" },
      { name: "number_range" },
      {
        name: "format",
        options: CardFormatOptions,
      },
      ...paginationParams([{ value: "facility_code" }, { value: "format" }]),
    ],
  },
  {
    name: "Get Card Format",
    path: `/card-formats/:card_format_id`,
  },
  {
    name: "Create Card Format",
    path: `/card-formats`,
    method: "POST",
    body: [
      { name: "name", required: true },
      {
        name: "format",
        options: CardFormatOptions,
      },
      { name: "site_id", required: true },
      { name: "first_number", required: true, type: "number" },
      { name: "last_number", required: true, type: "number" },
      { name: "offset" },
      { name: "facility_code", required: true },
      {
        name: "type",
        options: [{ label: "Card", value: "card" }],
        required: true,
      },
    ],
  },
  {
    name: "Update Card Format",
    path: `/card-formats/:card_format_id`,
    method: "PATCH",
    body: [
      { name: "name" },
      { name: "first_number" },
      { name: "last_number" },
      { name: "facility_code" },
    ],
  },
  {
    name: "Delete Card Format",
    path: `/card-formats/:card_format_id`,
    method: "DELETE",
  },
];
