import { formatTime, toIso8601Time } from "@/lib/formatters";
import { QueryKeys } from "@/types";

import { BaseQueryClient } from "../base/queries";

import { ScheduleInterval } from "./types";

const queryKey = QueryKeys.ScheduleInterval;

class ScheduleIntervalQueryClient extends BaseQueryClient<ScheduleInterval> {
  constructor() {
    super({ queryKey });
  }
  encode = (values: Partial<ScheduleInterval>) => {
    values.start_time = toIso8601Time(values.start_time as string);
    values.end_time = toIso8601Time(values.end_time as string);

    return values;
  };

  decode = (values: Partial<ScheduleInterval>) => {
    values.start_time = formatTime(values.start_time as string);
    values.end_time = formatTime(values.end_time as string);

    return values;
  };
}
export const ScheduleIntervalQueries = new ScheduleIntervalQueryClient();
