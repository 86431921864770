import { Option, Param } from "badmagic/dist/types";

export const booleanOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const paginationParams = (sortParams?: Option[]): Param[] => {
  return [
    { label: "Page", name: "page" },
    { label: "Per Page", name: "per_page" },
    sortParams && sortParams.length > 0
      ? { label: "Sort", name: "sort", options: sortParams }
      : null,
    sortParams && sortParams.length > 0
      ? {
          label: "Direction",
          name: "dir",
          options: [
            { label: "Ascending", value: "asc" },
            { label: "Descending", value: "desc" },
          ],
        }
      : null,
  ].filter((param) => param) as Param[];
};
