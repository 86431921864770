import { QueryKeys } from "@/types";

import { BaseQueryClient } from "../base/queries";

import { Firmware } from "./types";

const queryKey = QueryKeys.Firmware;

class FirmwareQueryClient extends BaseQueryClient<Firmware> {
  constructor() {
    super({ queryKey });
  }
}
export const FirmwareQueries = new FirmwareQueryClient();
