import { Route } from "badmagic/dist/types";

import { ScheduleIntervalDayMaskOptions } from "@/modules/schedule-intervals/types";

import { paginationParams } from "../shared";

export const scheduleIntervalRoutes: Route[] = [
  {
    name: "List Schedule Intervals",
    path: `/schedule-intervals`,
    qsParams: [{ name: "schedule_id", required: true }, ...paginationParams()],
  },
  {
    name: "Create Schedule Interval",
    path: `/schedule-intervals`,
    method: "POST",
    body: [
      {
        name: "days_list",
        options: ScheduleIntervalDayMaskOptions,
        array: true,
      },
      { name: "schedule_id", type: "number", required: true },
      { name: "start_time", placeholder: "17:00:00", defaultValue: "17:00:00" },
      { name: "end_time", placeholder: "18:00:00", defaultValue: "18:00:00" },
    ],
  },
  {
    name: "Get Schedule Interval",
    path: `/schedule-intervals/:schedule_interval_id`,
  },
  {
    name: "Update Schedule Interval",
    path: `/schedule-intervals/:schedule_interval_id`,
    method: "PATCH",
    body: [
      {
        name: "days_list",
        options: ScheduleIntervalDayMaskOptions,
        array: true,
      },
      { name: "start_time", placeholder: "17:00:00", defaultValue: "17:00:00" },
      { name: "end_time", placeholder: "18:00:00", defaultValue: "18:00:00" },
    ],
  },
  {
    name: "Delete Schedule Interval",
    path: `/schedule-intervals/:schedule_interval_id`,
    method: "DELETE",
  },
];
