import { QueryKeys } from "@/types";

import { BaseQueryClient } from "../base/queries";

import { Schedule } from "./types";

const queryKey = QueryKeys.Schedules;

class ScheduleQueryClient extends BaseQueryClient<
  Schedule & { site_id: number }
> {
  constructor() {
    super({ queryKey });
  }
}
export const ScheduleQueries = new ScheduleQueryClient();
