import { CredentialType, MemberAccessType } from "@/modules/credential/types";
import { SystemTypes } from "@/modules/site/types";

import { QueryKeys } from "./index";

export const SiteConfiguration = {
  [SystemTypes.Alloy]: {
    navigation: [
      QueryKeys.Members,
      QueryKeys.Events,
      QueryKeys.SystemHealth,
      QueryKeys.Hubs,
      QueryKeys.Schedules,
      QueryKeys.AccessLevels,
      QueryKeys.Doors,
      QueryKeys.Controllers,
      QueryKeys.CardFormats,
      QueryKeys.SiteImports,
      QueryKeys.ApiApplications,
      QueryKeys.UserActivityLogs,
    ],
    credentialTypes: [
      CredentialType.card,
      CredentialType.mobile,
      CredentialType.pin,
    ],
    memberAccessTypes: [
      MemberAccessType.Credentials,
      MemberAccessType.AccessLevels,
    ],
  },
  [SystemTypes.Salto]: {
    navigation: [
      QueryKeys.Members,
      QueryKeys.Hubs,
      QueryKeys.AccessLevels,
      QueryKeys.Doors,
      QueryKeys.Controllers,
      QueryKeys.ApiApplications,
    ],
    credentialTypes: [CredentialType.card, CredentialType.mobile],
    memberAccessTypes: [
      MemberAccessType.Credentials,
      MemberAccessType.AccessLevels,
      MemberAccessType.Doors,
    ],
  },

  [SystemTypes.Schlage]: {
    navigation: [
      QueryKeys.Members,
      QueryKeys.Operators,
      QueryKeys.AccessLevels,
      QueryKeys.Doors,
      QueryKeys.Encoders,
      QueryKeys.CardFormats,
      QueryKeys.ApiApplications,
      QueryKeys.Events,
      QueryKeys.UserActivityLogs,
    ],
    credentialTypes: [CredentialType.card, CredentialType.mobile],
    memberAccessTypes: [
      MemberAccessType.Credentials,
      MemberAccessType.AccessLevels,
      MemberAccessType.Doors,
    ],
  },
};
