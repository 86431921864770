import { QueryClient } from "@tanstack/react-query";

import { QueryKeys } from "@/types";

import { BaseQueryClient } from "../base/queries";

import { AccessLevelSchedule } from "./types";

const queryKey = QueryKeys.AccessLevelSchedules;

class AccessLevelScheduleQueryClient extends BaseQueryClient<AccessLevelSchedule> {
  constructor() {
    // You can only select doors for an access level schedule that have not yet been selected
    // for that access level. As such, we need to refresh the list of valid doors after modifying
    // access level schedules.
    const afterModify = async (queryClient: QueryClient) => {
      await queryClient.invalidateQueries([QueryKeys.Doors]);
    };
    super({ afterModify, queryKey });
  }
}
export const AccessLevelScheduleQueries = new AccessLevelScheduleQueryClient();
