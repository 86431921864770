import { useCallback, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { SiteQueries } from "@/modules/site/queries";
import { QueryKeys } from "@/types";

const FIVE_MINUTES = 5 * 60 * 1000;

export const useSiteAlerts = (siteId: number | undefined) => {
  const queryClient = useQueryClient();

  // Using React state over localStorage to avoid storage/env issues.
  const [dismissedAlerts, setDismissedAlerts] = useState<Set<string>>(
    new Set()
  );

  const { data: alerts } = SiteQueries.getAlertsQuery(siteId, {
    enabled: !!siteId,
    refetchInterval: FIVE_MINUTES,
    select: (data) => data.filter((alert) => !dismissedAlerts.has(alert.key)),
  });

  const dismissAlert = useCallback(
    (alertKey: string) => {
      // Update state to prevent alert from reappearing.
      setDismissedAlerts(dismissedAlerts.add(alertKey));
      // Update the query cache to remove the alert from the list.
      queryClient.setQueryData(
        [QueryKeys.SiteAlerts, siteId],
        alerts?.filter((alert) => alert.key !== alertKey)
      );
    },
    [alerts, queryClient, dismissedAlerts, siteId]
  );

  return { alerts, dismissAlert };
};
