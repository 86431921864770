import {
  PaginatedResponse,
  UseReducedInfiniteQueryOptions,
} from "@smartrent/hooks";
import {
  ListQueryResponse,
  useFormikSelectQuery,
  useSelectQuery,
} from "@smartrent/ui";
import { AxiosError } from "axios";
import {
  QueryClient,
  UseMutateAsyncFunction,
  UseMutationResult,
} from "@tanstack/react-query";

import {
  AxiosQueryHook,
  createAxiosMutation,
  createAxiosQuery,
  CreateMutationOptions,
} from "@/hooks/react-query";
import { apiClient } from "@/lib/api";
import { getErrorMessage } from "@/lib/axios-helpers";
import { QueryKeys } from "@/types";

import { TableFilters } from "@/modules/base/table/types";

import { BaseRecord } from "./types";

export const API_BASE_PATH = "/v1";

export class BaseQueryClient<T> {
  public createMessageSuccess = () => "Created Successfully.";
  public createMessageFailure = (err: any) =>
    `Failed to Create. ${getErrorMessage(err)}`;
  public updateMessageSuccess = () => "Update Successful.";
  public updateMessageFailure = (err: any) =>
    `Failed To Update. ${getErrorMessage(err)}`;
  public deleteMessageSuccess = () => "Delete Successful.";
  public deleteMessageFailure = (err: any) =>
    `Failed To Delete. ${getErrorMessage(err)}`;
  public createRedirectPath?: (created: Partial<T>, params?: any) => string;

  public queryKey: QueryKeys;
  public useQuery: AxiosQueryHook<
    { id?: number | undefined },
    T | null,
    T | null,
    AxiosError<any>
  >;
  public useList: AxiosQueryHook<
    any,
    ListQueryResponse<T & BaseRecord>,
    ListQueryResponse<T & BaseRecord>,
    AxiosError<any>
  >;
  public useCreateMutation: (
    options?: CreateMutationOptions<
      Partial<T>,
      any,
      { values: Partial<T> },
      unknown
    >
  ) => readonly [
    UseMutateAsyncFunction<Partial<T>, any, { values: Partial<T> }, unknown>,
    UseMutationResult<Partial<T>, any, { values: Partial<T> }, unknown>,
  ];
  public useUpdateMutation: (
    options?: CreateMutationOptions<T, any, { id: number; values: T }, unknown>
  ) => readonly [
    UseMutateAsyncFunction<T, any, { id: number; values: T }, unknown>,
    UseMutationResult<T, any, { id: number; values: T }, unknown>,
  ];
  public useDeleteMutation: (
    options?: CreateMutationOptions<T, any, { id: number }, unknown>
  ) => readonly [
    UseMutateAsyncFunction<T, any, { id: number }, unknown>,
    UseMutationResult<T, any, { id: number }, unknown>,
  ];

  protected afterModify?: (queryClient: QueryClient) => Promise<void>;

  constructor({
    afterModify,
    queryKey,
  }: {
    afterModify?: (queryClient: QueryClient) => Promise<void>;
    queryKey: QueryKeys;
  }) {
    this.afterModify = afterModify;
    this.queryKey = queryKey;

    this.useQuery = createAxiosQuery(
      this.queryKey,
      async ({ id }: { id?: number }) => {
        if (!id) return null;
        const { data } = await apiClient.get<T>(
          `${API_BASE_PATH}/${this.queryKey}/${id}`
        );
        return this.decode(data) as T;
      }
    );

    this.useList = createAxiosQuery(this.queryKey, async (filters: any) => {
      const { data } = await apiClient.get<ListQueryResponse<T & BaseRecord>>(
        `${API_BASE_PATH}/${this.queryKey}/`,
        {
          params: filters,
        }
      );

      return data as ListQueryResponse<T & BaseRecord>;
    });

    this.useCreateMutation = createAxiosMutation(
      async ({ values }: { values: Partial<T> }) => {
        const { data } = await apiClient.post<Partial<T>>(
          `${API_BASE_PATH}/${this.queryKey}`,
          this.encode(values)
        );
        return data;
      },
      {
        successToast: () => ({
          status: "success",
          message: this.createMessageSuccess(),
        }),
        errorToast: (err: any) => ({
          status: "error",
          message: this.createMessageFailure(err),
        }),
        onSettled: async (queryClient, result, err, member_id) => {
          await queryClient.invalidateQueries([this.queryKey]);
          if (this.afterModify) {
            await this.afterModify(queryClient);
          }
        },
      }
    );

    this.useUpdateMutation = createAxiosMutation(
      async ({ id, values }: { id: number; values: T }) => {
        const { data } = await apiClient.patch<T>(
          `${API_BASE_PATH}/${this.queryKey}/${id}`,
          this.encode(values)
        );
        return data;
      },
      {
        successToast: () => ({
          status: "success",
          message: this.updateMessageSuccess(),
        }),
        errorToast: (err: any) => ({
          status: "error",
          message: this.updateMessageFailure(err),
        }),
        onSettled: async (queryClient, result, err, id) => {
          await queryClient.invalidateQueries([this.queryKey, { id }]);
          await queryClient.invalidateQueries([this.queryKey]);
          if (this.afterModify) {
            await this.afterModify(queryClient);
          }
        },
      }
    );

    this.useDeleteMutation = createAxiosMutation(
      async ({ id }: { id: number }) => {
        const { data } = await apiClient.delete<T>(
          `${API_BASE_PATH}/${this.queryKey}/${id}`
        );
        return data;
      },
      {
        successToast: () => ({
          status: "success",
          message: this.deleteMessageSuccess(),
        }),
        errorToast: (err: any) => ({
          status: "error",
          message: this.deleteMessageFailure(err),
        }),
        onSettled: async (queryClient, result, err, id) => {
          await queryClient.invalidateQueries([this.queryKey, { id }]);
          await queryClient.invalidateQueries([this.queryKey]);
          if (this.afterModify) {
            await this.afterModify(queryClient);
          }
        },
      }
    );
  }

  fetch = async ({
    queryKey,
  }: {
    queryKey:
      | ReadonlyArray<unknown>
      | readonly [string, Record<string, unknown>, TableFilters];
  }) => {
    const [, , filters] = queryKey;
    const { data } = await apiClient.get<PaginatedResponse<T>>(
      `${API_BASE_PATH}/${this.queryKey}`,
      {
        params: filters,
      }
    );
    data.records = data.records.map(this.decode) as T[];
    return data as ListQueryResponse<T & BaseRecord>;
  };

  list = async (key: string, { filters }: { filters: { query: string } }) => {
    const { data } = await apiClient.get<PaginatedResponse<T>>(
      `${API_BASE_PATH}/${this.queryKey}`,
      {
        params: filters,
      }
    );
    data.records = data.records.map(this.decode) as T[];
    return data as ListQueryResponse<T & BaseRecord>;
  };

  select = ({
    filterParams = {},
    initialValue = "",
  }: {
    filterParams?: any;
    initialValue?: string;
  }) => {
    return useSelectQuery<PaginatedResponse<T>>(
      (inputValue) => [this.queryKey, { search: inputValue, ...filterParams }],
      ({ queryKey, pageParam = 1 }) => {
        const [, provided_filters, params] = queryKey;
        return this.list(this.queryKey, {
          filters: {
            ...(provided_filters as any),
            ...(params as any),
            page: pageParam,
          },
        });
      },
      {},
      { initialValue }
    );
  };

  /**
   * From: https://smartrent-ui.com/components/lists/table#async
   *
   * @defaultParams Any params you want to be passed in on every request (such as site_id)
   */
  tableSelectProps = ({
    defaultParams = {},
    opts = {},
  }: {
    defaultParams?: any;
    opts?: UseReducedInfiniteQueryOptions<PaginatedResponse<T>, any>;
  }) => {
    return useFormikSelectQuery(
      (inputValue) =>
        [this.queryKey, { search: inputValue, ...defaultParams }] as const,
      async ({ queryKey, pageParam = 1 }) => {
        const [, filters] = queryKey;
        return apiClient
          .get<PaginatedResponse<T>>(`${API_BASE_PATH}/${this.queryKey}`, {
            params: {
              page: pageParam,
              ...filters,
            },
          })
          .then((response) => response.data);
      },
      {
        keepPreviousData: true,
        ...opts,
      },
      {
        inputDebounceInterval: 250,
      }
    );
  };

  decode = (valueFromAPI: T | Partial<T>) => {
    return valueFromAPI;
  };

  encode = (ValueFromJS: T | Partial<T>) => {
    return ValueFromJS;
  };
}
