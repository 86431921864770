import { BaseRecord } from "../base/types";

export interface Site extends BaseRecord {
  organization_id?: number;
  name?: string;
  system_type: SystemTypes;
  timezone?: SiteTimeZones;
  video_intercoms_available?: boolean;
  intercoms_available?: boolean;
  always_schedule_id?: number;
  lockdown: boolean;
}

export enum SystemTypes {
  Alloy = "alloy_access",
  Salto = "salto",
  Schlage = "schlage",
}

const SystemFriendlyNames = {
  [SystemTypes.Alloy]: "Alloy Access",
  [SystemTypes.Salto]: "Salto",
  [SystemTypes.Schlage]: "Schlage",
};

export const getSystemFriendlyNames = (systemType?: SystemTypes) => {
  if (systemType && SystemFriendlyNames[systemType]) {
    return SystemFriendlyNames[systemType];
  }

  if (typeof systemType === "string") {
    return systemType;
  }

  return "unknown";
};

export const SystemTypeOptions = [
  { label: "Alloy Access", value: SystemTypes.Alloy },
  { label: "Salto", value: SystemTypes.Salto },
  { label: "Schlage", value: SystemTypes.Schlage },
];

export const ValidSystemTypes = [
  SystemTypes.Alloy,
  SystemTypes.Salto,
  SystemTypes.Schlage,
];

export enum SiteTimeZones {
  Honolulu = "Pacific/Honolulu",
  Anchorage = "America/Anchorage",
  Los_Angeles = "America/Los_Angeles",
  Denver = "America/Denver",
  Phoenix = "America/Phoenix",
  Chicago = "America/Chicago",
  New_York = "America/New_York",
}

export const ValidSiteTimeZones = [
  SiteTimeZones.Honolulu,
  SiteTimeZones.Anchorage,
  SiteTimeZones.Los_Angeles,
  SiteTimeZones.Denver,
  SiteTimeZones.Phoenix,
  SiteTimeZones.Chicago,
  SiteTimeZones.New_York,
];

export const SiteTimezoneOptions = [
  { label: SiteTimeZones.Honolulu, value: SiteTimeZones.Honolulu },
  { label: SiteTimeZones.Anchorage, value: SiteTimeZones.Anchorage },
  { label: SiteTimeZones.Los_Angeles, value: SiteTimeZones.Los_Angeles },
  { label: SiteTimeZones.Denver, value: SiteTimeZones.Denver },
  { label: SiteTimeZones.Phoenix, value: SiteTimeZones.Phoenix },
  { label: SiteTimeZones.Chicago, value: SiteTimeZones.Chicago },
  { label: SiteTimeZones.New_York, value: SiteTimeZones.New_York },
];

const getOffset = (timeZone = "UTC", date = new Date()) => {
  const utcDate = new Date(date.toLocaleString("en-US", { timeZone: "UTC" }));
  const tzDate = new Date(date.toLocaleString("en-US", { timeZone }));
  return (tzDate.getTime() - utcDate.getTime()) / 6e4;
};

export const TimeZoneOffsets = {
  [SiteTimeZones.Honolulu]: getOffset(SiteTimeZones.Honolulu),
  [SiteTimeZones.Anchorage]: getOffset(SiteTimeZones.Anchorage),
  [SiteTimeZones.Los_Angeles]: getOffset(SiteTimeZones.Los_Angeles),
  [SiteTimeZones.Denver]: getOffset(SiteTimeZones.Denver),
  [SiteTimeZones.Phoenix]: getOffset(SiteTimeZones.Phoenix),
  [SiteTimeZones.Chicago]: getOffset(SiteTimeZones.Chicago),
  [SiteTimeZones.New_York]: getOffset(SiteTimeZones.New_York),
};
