import { WorkspaceAuthStrategies, WorkspaceKeys } from "../types";

import { apiRoutes } from "./api-routes";

export const apiWorkspace = {
  id: WorkspaceKeys.Api,
  name: "V1 API",
  config: {
    baseUrl: `${process.env.API_BASE_URL}/api/v1`,
    authStrategies: [WorkspaceAuthStrategies.Hmac],
  },
  routes: apiRoutes,
};
