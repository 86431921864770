import { AxiosError } from "axios";

import { SolicitApiErrorData, SolicitApiErrorResponse } from "@/types/solicit";

import { isAxiosError } from "./api";
import { humanize } from "./helpers";

interface InternalAPIErrorResponse {
  errors: Array<[string]>;
  error: string;
}

export type InternalAPIAxiosErrorResponse =
  AxiosError<InternalAPIErrorResponse>;

export const getErrorMessage = (
  error: AxiosError<InternalAPIErrorResponse> | string
): string => {
  const defaultError = "Something went wrong.";

  if (isAxiosError(error)) {
    return formatAxiosError(error, defaultError);
  } else {
    return defaultError;
  }
};

const formatAxiosError = (
  error: AxiosError<InternalAPIErrorResponse>,
  defaultError: string
) => {
  const arrayResp = error.response?.data.errors
    ?.map((error: any) => {
      if (typeof error == "string") {
        return humanize(error);
      } else {
        if (error.field && error.description) {
          return `Error field: ${error.field}. ${error.description}`;
        } else
          return error.message || error.description || JSON.stringify(error);
      }
    })
    .join(", ");

  return arrayResp || error.response?.data.error || defaultError;
};

const getErrorResponse = (error: any) => {
  if (!error || !isAxiosError(error)) return null;
  return (error as AxiosError).response as SolicitApiErrorResponse<any>;
};

const hasFormFields = (item: any) =>
  Object.prototype.hasOwnProperty.call(item, "field") &&
  Object.prototype.hasOwnProperty.call(item, "description");

// Checks if the error follows the Solicit API error pattern
// (e.g., { field: "name", description: "is required" })
const isErrorWithFormFields = (error: any): boolean => {
  const response = getErrorResponse(error);
  if (!response) return false;

  const errors = response?.data?.errors || [];
  if (errors.length === 0) return false;

  const firstItem = errors[0];
  if (typeof firstItem === "string") return false;

  return hasFormFields(firstItem);
};

// Function to get and clean up error details from API responses for FormDrawer.
// Returns null, if the error does not follow the Solicit API error pattern.
export const getFormFieldsErrors = <T = any>(error: any) => {
  if (!isErrorWithFormFields(error)) return null;

  const response = (error as AxiosError)
    .response as SolicitApiErrorResponse<any>;
  const errors = response.data.errors as SolicitApiErrorData<T>[];

  return errors;
};
