import { AxiosError } from "axios";

import { QueryKeys } from "@/types";
import { apiClient } from "@/lib/api";

import { AxiosQueryHook, createAxiosQuery } from "@/hooks/react-query";

import { API_BASE_PATH, BaseQueryClient } from "../base/queries";

import { SystemHealthIssue, SystemHealthSummary } from "./types";

const queryKey = QueryKeys.SystemHealth;

class SystemHealthQueryClient extends BaseQueryClient<SystemHealthIssue> {
  public getSummary: AxiosQueryHook<
    { site_id?: number | undefined },
    SystemHealthSummary | null,
    SystemHealthSummary | null,
    AxiosError<any>
  >;

  constructor() {
    super({ queryKey });

    this.getSummary = createAxiosQuery(
      this.queryKey,
      async ({ site_id }: { site_id?: number }) => {
        if (!site_id) return null;
        const { data } = await apiClient.get<SystemHealthSummary>(
          `${API_BASE_PATH}/${this.queryKey}/summary?site_id=${site_id}`
        );
        return data;
      }
    );
  }

  getIssues = async (site_id: number) => {
    const data = await apiClient.get(`${API_BASE_PATH}/${this.queryKey}`, {
      params: { site_id },
    });
    return data;
  };
}
export const SystemHealthQueries = new SystemHealthQueryClient();
