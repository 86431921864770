import { Workspace } from "badmagic/dist/types";

import { WorkspaceAuthStrategies, WorkspaceKeys } from "../types";

import { passRoutes } from "./pass-routes";

export const passWorkspace: Workspace = {
  id: WorkspaceKeys.Pass,
  name: "Alloy Pass API",
  config: {
    baseUrl: `${process.env.API_BASE_URL}/pass-api/v1`,
    loginPath: "/session",
    refreshPath: "/session/refresh",
    pathToTokens: "data",
    authStrategies: [WorkspaceAuthStrategies.PassBearer],
  },
  routes: passRoutes,
};
