import { Method } from "badmagic";

import { humanize } from "@/lib/helpers";
import { AkuvoxApiPayload } from "@/pages/site/controllers/debug/panels/AkvxDebugPanel";

export interface AKVXConfig {
  auth_mode?: AuthMode;
  connection_string?: string;
  controller_id?: number;
  host?: string;
  password?: string;
  port?: string;
  username?: string;
}

export enum AuthMode {
  none = "none",
  allowlist = "allowlist",
  basic = "basic",
  digest = "digest",
}

export const AuthModeOptions = [
  { value: AuthMode.none, label: humanize(AuthMode.none) },
  { value: AuthMode.allowlist, label: humanize(AuthMode.allowlist) },
  { value: AuthMode.basic, label: humanize(AuthMode.basic) },
  { value: AuthMode.digest, label: humanize(AuthMode.digest) },
];

export const ValidAuthModes = [
  AuthMode.none,
  AuthMode.allowlist,
  AuthMode.basic,
  AuthMode.digest,
];

export const MethodOptions = [
  { label: "Get", value: "GET" },
  { label: "Post", value: "POST" },
];

export const PathOptions = (method: Method) => {
  const options: Array<{ label: string; value: string; defaultValue?: any }> = [
    { label: "Get System Info", value: "/api/system/info" },
    { label: "Get Controller Status", value: "/api/system/status" },
    { label: "Reboot The Controller", value: "/api/system/reboot" },
    { label: "Get Controller Config", value: "/api/config/get" },
    { label: "/api/firmware/status", value: "/api/firmware/status" },
    { label: "/api/firmware/prepare", value: "/api/firmware/prepare" },
    { label: "/api/firmware/upgrade", value: "/api/firmware/upgrade" },
    { label: "/api/config/reset_factory", value: "/api/config/reset_factory" },
    { label: "/api/relay/get", value: "/api/relay/get" },
    { label: "/api/relay/status", value: "/api/relay/status" },
    { label: "/api/relay/trig", value: "/api/relay/trig" },
    { label: "/api/input/get", value: "/api/input/get" },
    { label: "/api/input/status", value: "/api/input/status" },
    { label: "/api/publiccode/get", value: "/api/publiccode/get" },
    { label: "/api/privatekey/get", value: "/api/privatekey/get" },
    { label: "Get Users", value: "/api/user/get" },
    { label: "Clear All Credentials", value: "/api/privatekey/clear" },
    { label: "/api/rfkey/get", value: "/api/rfkey/get" },
    { label: "/api/rfkey/clear", value: "/api/rfkey/clear" },
    {
      label: "Get Contacts",
      value: "/api/contact/get",
      defaultValue: {
        target: "contact",
        action: "get",
      },
    },
    { label: "Clear All Contacts", value: "/api/contact/clear" },
    { label: "/api/group/get", value: "/api/group/get" },
    { label: "/api/group/clear", value: "/api/group/clear" },
    { label: "Get Call Status", value: "/api/call/status" },
    { label: "/api/call/dial", value: "/api/call/dial" },
    { label: "/api/call/hangup", value: "/api/call/hangup" },
    { label: "/api/sip/get", value: "/api/sip/get" },
    { label: "/api/sip/status", value: "/api/sip/status" },
    { label: "/api/dialreplace/get", value: "/api/dialreplace/get" },
    { label: "/api/dialreplace/clear", value: "/api/dialreplace/clear" },
    { label: "/api/dialreplace/import", value: "/api/dialreplace/import" },
    { label: "/api/log/get", value: "/api/log/get" },
    { label: "/api/log/clear", value: "/api/log/clear" },
    { label: "/api/calllog/get", value: "/api/calllog/get" },
    { label: "/api/calllog/clear", value: "/api/calllog/clear" },
    { label: "/api/doorlog/get", value: "/api/doorlog/get" },
    { label: "/api/doorlog/clear", value: "/api/doorlog/clear" },
    { label: "/api/pcap/status", value: "/api/pcap/status" },
    { label: "/api/pcap/start", value: "/api/pcap/start" },
    { label: "/api/pcap/stop", value: "/api/pcap/stop" },
  ];
  if (method === "POST") {
    return options.concat([
      { label: "/api/firmware/upload", value: "/api/firmware/upload" },
      {
        label: "/api/config/set",
        value: "/api/config/set",
        defaultValue: {
          target: "config",
          action: "set",
          data: {
            "Config.DoorSetting.DISPLAY.Key1Type": "2",
            "Config.DoorSetting.DISPLAY.Key1Label": "Pin Code",
            "Config.DoorSetting.DISPLAY.Key1Number": "",
            "Config.DoorSetting.DISPLAY.Key2Type": "4",
            "Config.DoorSetting.DISPLAY.Key2Label": "Resident",
            "Config.DoorSetting.DISPLAY.Key2Number": "",
            "Config.DoorSetting.DISPLAY.Key3Type": "5",
            "Config.DoorSetting.DISPLAY.Key3Label": "Leasing Office",
            "Config.DoorSetting.DISPLAY.Key3Number": "17576420327",
            "Config.DoorSetting.DISPLAY.Key4Type": "7",
            "Config.DoorSetting.DISPLAY.Key4Label": "",
            "Config.DoorSetting.DISPLAY.Key4Number": "",
            "Config.DoorSetting.DISPLAY.Key5Type": "7",
            "Config.DoorSetting.DISPLAY.Key5Label": "",
            "Config.DoorSetting.DISPLAY.Key5Number": "",
            "Config.DoorSetting.DISPLAY.Key6Type": "7",
            "Config.DoorSetting.DISPLAY.Key6Label": "",
            "Config.DoorSetting.DISPLAY.Key6Number": "",
          },
        },
      },
      { label: "/api/config/import", value: "/api/config/import" },
      { label: "/api/relay/set", value: "/api/relay/set" },
      { label: "/api/input/set", value: "/api/input/set" },
      { label: "/api/publiccode/set", value: "/api/publiccode/set" },
      { label: "Add a Credential", value: "/api/privatekey/add" },
      { label: "Set a Credential", value: "/api/privatekey/set" },
      { label: "/api/privatekey/del", value: "/api/privatekey/del" },
      { label: "/api/privatekey/import", value: "/api/privatekey/import" },
      { label: "/api/rfkey/add", value: "/api/rfkey/add" },
      { label: "/api/rfkey/set", value: "/api/rfkey/set" },
      { label: "/api/rfkey/del", value: "/api/rfkey/del" },
      { label: "/api/rfkey/import", value: "/api/rfkey/import" },
      {
        label: "Add Contact",
        value: "/api/contact/add",
        defaultValue: {
          target: "contact",
          action: "add",
          data: {
            num: 1,
            item: [
              {
                DialType: "0",
                Group: "",
                Name: "Placeholder, U",
                Phone: "15558765309",
              },
            ],
          },
        },
      },
      {
        label: "Add User",
        value: "/api/user/add",
        defaultValue: {
          target: "user",
          action: "add",
          data: {
            item: [
              {
                Building: "",
                CardCode: "",
                FaceUrl: "",
                LiftFloorNum: "0",
                Name: "John Doe",
                PrivatePIN: "8631",
                Room: "",
                Type: "-1",
                ScheduleRelay: "1001-123",
                UserID: "",
                ID: "",
                WebRelay: "0",
              },
            ],
          },
        },
      },
      {
        label: "Delete User",
        value: "/api/user/del",
        defaultValue: {
          target: "user",
          action: "del",
          data: {
            item: [
              {
                ID: "AKUVOXS_USER_ID",
              },
            ],
          },
        },
      },
      {
        label: "Update Contact",
        value: "/api/contact/set",
        defaultValue: {
          target: "contact",
          action: "add",
          data: {
            num: 1,
            item: [
              {
                DialType: "0",
                Group: "",
                Name: "Placeholder, U",
                Phone: "15558765309",
              },
            ],
          },
        },
      },
      {
        label: "Delete Contact",
        value: "/api/contact/del",
        defaultValue: {
          target: "contact",
          action: "del",
          data: {
            item: [
              {
                Name: "Placeholder, U",
              },
            ],
          },
        },
      },
      { label: "/api/contact/import", value: "/api/contact/import" },
      { label: "/api/log/set", value: "/api/log/set" },
      { label: "/api/group/add", value: "/api/group/add" },
      { label: "/api/group/set", value: "/api/group/set" },
      { label: "/api/dialreplace/add", value: "/api/dialreplace/add" },
      { label: "/api/dialreplace/set", value: "/api/dialreplace/set" },
      { label: "/api/dialreplace/del", value: "/api/dialreplace/del" },
      { label: "/api/sip/set", value: "/api/sip/set" },
      { label: "/api/doorlog/del", value: "/api/doorlog/del" },
      { label: "/api/calllog/del", value: "/api/calllog/del" },
      {
        label: "/api/doorsetting/set",
        value: "Update Door Settings",
        defaultValue: {
          target: "config",
          action: "set",
          data: {
            "Config.DoorSetting.DISPLAY.Key1Type": "0",
            "Config.DoorSetting.DISPLAY.Key1Label": "",
            "Config.DoorSetting.DISPLAY.Key1Number": "",
          },
        },
      },
    ]);
  }
  if (method === "GET") {
    return options.concat([
      { label: "/api/config/export", value: "/api/config/export" },
      { label: "/api/privatekey/export", value: "/api/privatekey/export" },
      { label: "/api/rfkey/export", value: "/api/rfkey/export" },
      { label: "/api/contact/export", value: "/api/contact/export" },
      { label: "/api/dialreplace/export", value: "/api/dialreplace/export" },
      { label: "/api/log/export", value: "/api/log/export" },
      { label: "/api/calllog/export", value: "/api/calllog/export" },
      { label: "/api/doorlog/export", value: "/api/doorlog/export" },
      { label: "/api/pcap/export", value: "/api/pcap/export" },
    ]);
  }

  return options;
};

export const getDefaultPathValue = (values: AkuvoxApiPayload) => {
  const options = PathOptions(values.method);
  return options.find(
    (option) => option.defaultValue && option.value === values.path
  );
};
