import { BaseRecord } from "../base/types";

export interface SiteImport extends BaseRecord {
  site_id?: number;
  file_name?: string;
  status?: string;
  records?: string;
  file?: any;
  inserted_at?: string;
  updated_at?: string;
  missing_card_bank_info?: MissingCardBankInfo[];
}

export enum SiteImportStatus {
  new = "new",
}

export interface MissingCardBankInfo {
  count: number;
  facility_code: string;
  max: number;
  min: number;
}

export const siteImportTemplateData = [
  [
    "access_level",
    "card_number",
    "department",
    "email",
    "facility_code",
    "first_name",
    "last_name",
    "phone",
    "pin_code",
    "remote_id",
    "title",
    "valid_from",
    "valid_to",
  ],
];
