export interface SMRTPConfig {
  timezone: TimeZone;
}

enum TimeZone {
  Honolulu = "Pacific/Honolulu",
  Anchorage = "America/Anchorage",
  Los_Angeles = "America/Los_Angeles",
  Denver = "America/Denver",
  Phoenix = "America/Phoenix",
  Chicago = "America/Chicago",
  New_York = "America/New_York",
}

export const TimeZoneOptions = [
  { value: TimeZone.Honolulu, label: "Pacific/Honolulu" },
  { value: TimeZone.Anchorage, label: "America/Anchorage" },
  { value: TimeZone.Los_Angeles, label: "America/Los Angeles" },
  { value: TimeZone.Denver, label: "America/Denver" },
  { value: TimeZone.Phoenix, label: "America/Phoenix" },
  { value: TimeZone.Chicago, label: "America/Chicago" },
  { value: TimeZone.New_York, label: "America/New York" },
];

export const ValidTimeZoneOptions = [
  TimeZone.Honolulu,
  TimeZone.Anchorage,
  TimeZone.Los_Angeles,
  TimeZone.Denver,
  TimeZone.Phoenix,
  TimeZone.Chicago,
  TimeZone.New_York,
];
