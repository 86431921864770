import { QueryKeys } from "@/types";
import { apiClient } from "@/lib/api";

import { createAxiosQuery } from "@/hooks/react-query";

import { API_BASE_PATH, BaseQueryClient } from "../base/queries";

import { ApiApplication } from "./types";

const queryKey = QueryKeys.ApiApplications;

class ApiApplicationQueryClient extends BaseQueryClient<ApiApplication> {
  public useDefaultWebhookUrl;

  constructor() {
    super({ queryKey });

    this.useDefaultWebhookUrl = createAxiosQuery(
      this.queryKey,
      async (filters: any) => {
        const { data } = await apiClient.get(
          `${API_BASE_PATH}/${this.queryKey}/webhook_configuration`,
          { params: filters }
        );

        return data;
      }
    );
  }
}
export const ApiApplicationQueries = new ApiApplicationQueryClient();
