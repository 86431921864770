import { Route } from "badmagic/dist/types";

import { SiteTimezoneOptions, SystemTypeOptions } from "@/modules/site/types";

import { booleanOptions, paginationParams } from "../shared";

export const siteRoutes: Route[] = [
  {
    name: "List users sites",
    path: `/sites`,
    qsParams: [
      { name: "organization_id" },
      { name: "controller_id" },
      { name: "system_type" },
      { name: "name" },
      { name: "user_id" },
      ...paginationParams([
        { value: "name", label: "Site Name" },
        { value: "system_type", label: "Site Type" },
      ]),
    ],
  },
  {
    name: "Create a new Site",
    path: `/sites`,
    method: "POST",
    body: [
      { name: "system_type", options: SystemTypeOptions, required: true },
      { name: "name", required: true },
      { name: "timezone", options: SiteTimezoneOptions, required: true },
    ],
  },
  {
    name: "Get Site by id",
    path: `/sites/:site_id`,
  },
  {
    name: "Update Site",
    path: `/sites/:site_id`,
    method: "PATCH",
    body: [
      { name: "name", required: true },
      { name: "timezone", options: SiteTimezoneOptions, required: true },
    ],
  },
  {
    name: "Sync all site controllers",
    path: `/sites/:site_id/sync`,
    method: "POST",
    body: [{ name: "force", options: booleanOptions }],
  },
];
