import { humanize } from "@/lib/helpers";

import { BaseRecord } from "../base/types";
import { Door } from "../door/types";
import { Panel } from "../panel/types";

export interface Output extends BaseRecord {
  name: string;
  output_number: number;
  synced_at: string;
  mode: OutputMode;
  status: string;
  channel: string;
  panel?: Panel;
  door?: Door;
}

enum OutputMode {
  normal = "normal",
  normal_inactive = "normal_inactive",
  normal_active = "normal_active",
  inverted = "inverted",
  inverted_inactive = "inverted_inactive",
  inverted_active = "inverted_active",
}

export const OutputModeOptions = [
  {
    value: OutputMode.normal,
    label: humanize(OutputMode.normal),
  },
  {
    value: OutputMode.normal_inactive,
    label: humanize(OutputMode.normal_inactive),
  },
  {
    value: OutputMode.normal_active,
    label: humanize(OutputMode.normal_active),
  },
  {
    value: OutputMode.inverted,
    label: humanize(OutputMode.inverted),
  },
  {
    value: OutputMode.inverted_inactive,
    label: humanize(OutputMode.inverted_inactive),
  },
  {
    value: OutputMode.inverted_active,
    label: humanize(OutputMode.inverted_active),
  },
];

export const ValidOutputModes = [
  OutputMode.normal,
  OutputMode.normal_inactive,
  OutputMode.normal_active,
  OutputMode.inverted,
  OutputMode.inverted_inactive,
  OutputMode.inverted_active,
];
