import { Route } from "badmagic/dist/types";

import { booleanOptions, paginationParams } from "../shared";

export const hubRoutes: Route[] = [
  {
    name: "List Hubs",
    path: "/hubs",
    qsParams: [
      { name: "site_id" },
      { name: "name" },
      { name: "serial" },
      { name: "serial_like" },
      { name: "search" },
      { name: "virtual", options: booleanOptions },
      ...paginationParams([
        { value: "name", label: "Hub Name" },
        { value: "serial", label: "Serial" },
        { value: "virtual", label: "Virtual" },
        { value: "online", label: "Online" },
      ]),
    ],
  },
  {
    name: "Create Hub",
    path: "/hubs",
    method: "POST",
    body: [
      { name: "site_id" },
      { name: "name" },
      { name: "serial", required: true },
    ],
  },
  {
    name: "Get Hub",
    path: "/hubs/:hub_id",
  },
  {
    name: "Update Hub",
    path: "/hubs/:hub_id",
    method: "PATCH",
    body: [{ name: "name" }],
  },
  {
    name: "Delete Hub",
    path: "/hubs/:hub_id",
    method: "DELETE",
  },
  {
    name: "Refresh Hub online status",
    path: "/hubs/:hub_id/refresh_status",
  },
];
