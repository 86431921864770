import { apiClient } from "@/lib/api";
import { getErrorMessage } from "@/lib/axios-helpers";
import { EMPTY_PERMISSIONS } from "@/context/PolicyContext";
import { createAxiosMutation, createAxiosQuery } from "@/hooks/react-query";
import { API_BASE_PATH, BaseQueryClient } from "@/modules/base/queries";
import { QueryKeys } from "@/types";

import { PermissionsList, User } from "./types";

const queryKey = QueryKeys.Users;

class UserQueryClient extends BaseQueryClient<User> {
  resendInviteMutation;
  usePermissionsQuery;

  constructor() {
    super({ queryKey });

    this.resendInviteMutation = createAxiosMutation(
      async (user: User) => {
        const { data } = await apiClient.post(
          `${API_BASE_PATH}/${this.queryKey}/${user.id}/resend-invite`
        );
        return data;
      },
      {
        successToast: () => ({
          status: "success",
          message: "Successfully Resent Invite",
        }),
        errorToast: (err: any) => ({
          status: "error",
          message: `Unable To Resend Invitation. ${getErrorMessage(err)}`,
        }),
      }
    );

    this.usePermissionsQuery = createAxiosQuery("policy", async (user) => {
      if (!user) return EMPTY_PERMISSIONS;

      const { data } = await apiClient.get<PermissionsList>(
        `${API_BASE_PATH}/policy`
      );
      return data;
    });
  }
}
export const UserQueries = new UserQueryClient();
