import { Route } from "badmagic/dist/types";

import {
  ValidControllerModels,
  HumanReadableControllerModels,
  ControllerModelOptions,
  ControllerProtocolOptions,
} from "@/modules/controller/types";

import { booleanOptions } from "../shared";

export const controllerRoutes: Route[] = [
  {
    name: "List Controllers",
    path: `/controllers`,
    qsParams: [
      { name: "site_id" },
      { name: "online", options: booleanOptions },
      { name: "protocol", options: ControllerProtocolOptions },
      { name: "model", options: ControllerModelOptions },
    ],
  },
  {
    name: "Create Controller",
    path: `/controllers`,
    method: "POST",
    body: [
      {
        name: "model",
        options: ValidControllerModels.map((value: string) => ({
          label: HumanReadableControllerModels[value],
          value: value,
        })),
      },
      { name: "name" },
      { name: "connection_string" },
      { name: "hub_id" },
    ],
  },
  {
    name: "Get Controller",
    path: `/controllers/:controller_id`,
  },
  {
    name: "Update Controller",
    path: `/controllers/:controller_id`,
    method: "PUT",
    body: [{ name: "name" }],
  },
  {
    name: "Delete Controller",
    path: `/controllers/:controller_id`,
    method: "DELETE",
  },
  {
    name: "Sync Controller",
    path: `/controllers/:controller_id/sync`,
    method: "POST",
    body: [{ name: "force", options: booleanOptions, defaultValue: false }],
  },
];
