import { useCallback, useReducer, Reducer } from "react";

interface PushReplaceAction<T> {
  type: "push" | "replace";
  payload: T;
}

interface PopAction {
  type: "pop";
  payload: number;
}

interface ResetAction {
  type: "reset";
}

type StackAction<T> = PushReplaceAction<T> | PopAction | ResetAction;

function stackReducer<T>(state: T[], action: StackAction<T>) {
  switch (action.type) {
    case "push":
      return [...state, action.payload];
    case "pop":
      return state.slice(0, -1);
    case "replace":
      return [...state.slice(0, -1), action.payload];
    case "reset":
      return [];
    default:
      return state;
  }
}

export function useStack<T = any>(initialState: T[] = []) {
  const [stack, dispatch] = useReducer<Reducer<T[], StackAction<T>>>(
    stackReducer,
    initialState
  );

  const push = useCallback((payload: T) => {
    dispatch({ type: "push", payload });
  }, []);

  const pop = useCallback((count: number = 1) => {
    dispatch({ type: "pop", payload: count });
  }, []);

  const replace = useCallback((payload: T) => {
    dispatch({ type: "replace", payload });
  }, []);

  const reset = useCallback(() => {
    dispatch({ type: "reset" });
  }, []);

  return { stack, push, replace, pop, reset };
}
